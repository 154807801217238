import { Company } from "../model/company.model";
import * as CompanyActions from '../actions/company.actions';
import { createReducer, on, Action } from "@ngrx/store";
import { Image } from "../model/product.model";

export interface CompanyState {
    companies: Company[];
    currentCompany: Company;
}

export const initialCompanyState = {
    companies: new Array<Company>(),
    currentCompany: new Company()
}

const reducer = createReducer(
    initialCompanyState,
    on(CompanyActions.loadCompanySuccess,
        (state, { company }) => ({
            ...state, currentCompany: company
        })
    ),
    on(CompanyActions.setCurrentCompanySuccess,
        (state, { company }) => ({
            ...state, currentCompany: company
        })
    ),
    on(CompanyActions.loadCompaniesSuccess,
        (state, { companies }) => ({
            ...state, companies: companies, currentCompany: companies.length == 1?companies[0]:new Company()
        })
    ),
    on(CompanyActions.updateCompanySuccess,
        (state, { company }) => ({
            ...state, currentCompany: company
        })
    ),
    on(CompanyActions.updateCompanyLogoSuccess,
        (state, { company, image }) => ({
            ...state, currentCompany: company, companies: updateImage(company, image, state.companies)
        })
    )
)

export function companyReducer(state: CompanyState | undefined, action: Action) {
    return reducer(state, action);
}

function updateImage(company: Company, image: Image, state: Company[]) : Array<Company> {
    let companies = [...state];
    companies.forEach((co, i) => {
        if(co.id === company.id) {
            company.logo = image
            companies[i] = company;
        }
    })
    return companies;   
}