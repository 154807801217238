import { RxStompConfig } from '@stomp/rx-stomp';
import { environment } from 'src/environments/environment';
import { WebsocketService } from './websocket.service';

export function webSocketServiceFactory() {
    const stompConfig: RxStompConfig = {
        // Which server?
        brokerURL: environment.websocketUrl,
      
      
        /* connectHeaders: {
          login: 'guest',
          passcode: 'guest',
        }, */
      
        heartbeatIncoming: 0,
        heartbeatOutgoing: 20000,
        reconnectDelay: 500,
    };

    const rxStomp = new WebsocketService();
    rxStomp.configure(stompConfig);
    rxStomp.activate();
    return rxStomp;
}