<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
    <div class="row">
        <div class="col-md-12">
          <form ngNativeValidate (ngSubmit)="submit()">
            <div class="card">
              <div class="card-header">
                <h5 class="title">{{getTitle()}} Product</h5>
              </div>
              <div class="card-body" >
                <div class="row">
                  <div class="col-md-8 pr-1">
                    <div class="row">
                      <div class="col-md-5 pr-1">
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="Name" [(ngModel)]="product.name" name="name">
                        </div>
                      </div>
                    </div> 
                    <div class="row">
                      <div class="col-md-5 pr-1">
                        <div class="form-group">
                          <tag-input [secondaryPlaceholder]="'Search Tags'" [separatorKeyCodes]="[13,44]" [modelAsStrings]="true" [(ngModel)]="product.tags" name="tags"></tag-input>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-11 pr-1">
                        <div class="form-group">
                          <angular-editor class="product-description" name="description" [(ngModel)]="product.description" [config]="editorConfig" name="description"></angular-editor>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-1 pr-1">
                        <div class="form-group">
                          <div class="form-check">
                            <input type="checkbox" class="form-check" [(ngModel)]="product.active" name="active" id="active">
                            <label class="form-check-label" for="active">Active</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1 pl-1">
                        <div class="form-group">
                          <div class="form-check">
                            <input type="checkbox" class="form-check" [(ngModel)]="product.featured" name="featured" id="featured">
                            <label class="form-check-label" for="featured">Featured</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1 pl-1">
                        <div class="form-group">
                          <div class="form-check">
                            <input type="checkbox" class="form-check" [(ngModel)]="product.taxable" name="taxable" id="taxable">
                            <label class="form-check-label" for="taxable">Taxable</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" *ngIf="product.taxable">
                        <div class="input-group">
                          <input type="number" class="form-control" min="0" max="100" step="0.01" placeholder="Tax Rate" [(ngModel)]="product.taxRate" name="taxRate">
                          <div class="input-group-append">
                            <span class="input-group-text">
                                <fa-icon [icon]="faInfoCircle" ngbTooltip="Enter the tax rate percent for this item"></fa-icon>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 pr-5">
                    <div class="form-group" *ngIf="categories$ | async as categories">
                      <label>Categories</label>
                      <ngx-treeview [config]="treeViewConfig" [items]="categories" (selectedChange)="onSelectedChanged($event)" >
                      </ngx-treeview>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 pr-5">
                    <h4>Images</h4>
                    <app-file-dnd [images]="uploadedFiles" (uploadedFiles)="onValueChange($event)" (profileImage)="setProfileImage($event)" [selectedProfileImage]="product.profileImage?.fileName"></app-file-dnd>
                  </div>
                </div>
              <br/>
                <div *ngIf="!showVariants" class="row">
                  <div class="col-md-5 pl-5"> 
                    <div class="input-group">
                      <input class="form-control" placeholder="SKU" type="text" [(ngModel)]="product.variants[0].sku" name="sku" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                            <fa-icon [icon]="faInfoCircle" ngbTooltip="This is a unique identifier you setup to track your products"></fa-icon>
                        </span>
                      </div>
                    </div>
                    <div class="form-group">
                      <input class="form-control" placeholder="Price" type="text" [ngModel]="product.variants[0].amount.amount | currency" (ngModelChange)="product.variants[0].amount=currencyInputChanged($event)" [ngModelOptions]="{updateOn:'blur'}" name="amount" required />
                    </div>
                    <div class="input-group">
                      <input class="form-control" placeholder="Sales Price" type="text" [ngModel]="product.variants[0].saleAmount?.amount | currency" (ngModelChange)="product.variants[0].saleAmount=currencyInputChanged($event)" [ngModelOptions]="{updateOn:'blur'}" name="saleAmount" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                            <fa-icon [icon]="faInfoCircle" ngbTooltip="Enter an amount here if you want the product to show a sale price"></fa-icon>
                        </span>
                      </div>
                    </div>
                    <div class="input-group">
                      <input class="form-control" placeholder="My Cost" type="text" [ngModel]="product.variants[0].cost?.amount | currency" (ngModelChange)="product.variants[0].cost=currencyInputChanged($event)" [ngModelOptions]="{updateOn:'blur'}" name="cost" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                            <fa-icon [icon]="faInfoCircle" ngbTooltip="This will be used to help calculate your profits"></fa-icon>
                        </span>
                      </div>
                    </div>
                    <div class="input-group">
                      <input class="form-control" placeholder="Quatity" type="number" [(ngModel)]="product.variants[0].inventory[0].quantity" name="quantity" />
                      <div class="input-group-append">
                        <span class="input-group-text">
                            <fa-icon [icon]="faInfoCircle" ngbTooltip="This will be used to determine if a product is available to add to cart or not, if not set it can be purchased even if you do not have any"></fa-icon>
                        </span>
                      </div>
                    </div>
                  </div> 
                </div>
                <div *ngIf="!showVariants" class="row float-right">
                  <div class="col-md-5 pl-1" >
                    <button type="button" class="btn btn-round btn-primary" (click)="addOptionGroup()">
                      Add Options
                    </button>
                  </div>        
                  <div  class="col-md-2 pl-1">
                    <button type="submit" class="btn btn-round btn-default btn-simple">{{getTitle()}}</button>
                  </div>
                </div>  
              </div>
            </div> 
            <div *ngIf="showVariants" class="card">
              <div class="card-header">
                <h5 class="title">Options</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-5 pr-1">
                    <ng-container *ngFor="let optionGroup of product.optionGroups; index as i">
                      <hr/>
                      <app-option-group
                        (addEvent)="addOption($event)"
                        (removeEvent)="removeOption($event)"
                        [optionGroup]="optionGroup"
                      ></app-option-group>
                      <fa-icon class="option-icon" [icon]="faMinusCircle" (click)="removeOptionGroup(optionGroup)"></fa-icon>
                    </ng-container>
                    <fa-icon class="option-icon" [icon]="faPlusCircle" (click)="addOptionGroup()"></fa-icon>
                  </div>
                </div>
                <div *ngIf="hasOptions()" class="row pl-3">
                  <div class="col-md-12">
                    <table class="table">
                      <thead>
                          <tr>
                              <th class="text-center">Options</th>
                              <th class="text-center">Images<fa-icon [icon]="faInfoCircle" ngbTooltip="Select the images you want to appear for these options"></fa-icon></th>
                              <th class="text-center">SKU<fa-icon [icon]="faInfoCircle" ngbTooltip="This is a unique identifier you setup to track your products"></fa-icon></th>
                              <th class="text-center">Price</th>
                              <th class="text-center">Sales Price<fa-icon [icon]="faInfoCircle" ngbTooltip="Enter an amount here if you want the product to show a sale price"></fa-icon></th>
                              <th class="text-center">My Cost<fa-icon [icon]="faInfoCircle" ngbTooltip="This will be used to help calculate your profits"></fa-icon></th>
                              <th class="text-center">Quantity<fa-icon [icon]="faInfoCircle" ngbTooltip="This will be used to determine if a product is available to add to cart or not, if not set it can be purchased even if you do not have any"></fa-icon></th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let variant of product.variants; index as i">
                          <td>
                            <tag-input name="tags_{{i}}" [placeholder]="''" [secondaryPlaceholder]="''" [identifyBy]="'id'" [displayBy]="'label'" [(ngModel)]="variant.options" [removable]="false"></tag-input>   
                          </td>
                          <td>
                            <ng-multiselect-dropdown name="images_{{i}}" [ngModel]="variant.images" [placeholder]="'Select Images'" [settings]="dropdownSettings" [data]="images" (onSelect)="onItemSelect($event, variant)" (onDeSelect)="onItemDeSelect($event, variant)" (onSelectAll)="onSelectAll($event, variant)"></ng-multiselect-dropdown>
                          </td>
                          <td>
                            <input class="form-control" placeholder="SKU" type="text" [(ngModel)]="variant.sku" name="sku_{{ i }}" />
                          </td>
                          <td>
                            <input class="form-control" placeholder="Price" type="text" [ngModel]="variant.amount.amount | currency" (ngModelChange)="variant.amount=currencyInputChanged($event)" [ngModelOptions]="{updateOn:'blur'}" name="amount_{{ i }}" required />
                          </td>
                          <td>
                            <input class="form-control" placeholder="Sales Price" type="text" [ngModel]="variant.saleAmount?.amount | currency" (ngModelChange)="variant.saleAmount=currencyInputChanged($event)" [ngModelOptions]="{updateOn:'blur'}" name="saleAmount_{{ i }}" />
                          </td>
                          <td>
                            <input class="form-control" placeholder="My Cost" type="text" [ngModel]="variant.cost?.amount | currency" (ngModelChange)="variant.cost=currencyInputChanged($event)" [ngModelOptions]="{updateOn:'blur'}" name="cost_{{ i }}" />
                          </td>
                          <td>
                            <input class="form-control" placeholder="Quatity" type="number" [(ngModel)]="variant.inventory[0].quantity" name="quantity_{{ i }}" />
                          </td>
                      <hr/>
                      
                        </tbody>
                      </table>
                  </div>
                  <div class="float-md-right pr-5">
                    <button type="submit" class="btn btn-round btn-default btn-simple">{{getTitle()}}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
    </div>
</div>

