<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
<div class="row">
    <div class="col-md-8">
      <form (ngSubmit)="submit(promotion)">
        <div class="card">
          <div class="card-header">
            <h5 class="title">{{getTitle()}} Promotion</h5>
          </div>
          <div class="card-body" >
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3 pr-1">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Type" [(ngModel)]="promotion.type" name="type">
                    </div>
                  </div>
                  <div class="col-md-3 pr-1">
                    <div class="dp-hidden position-absolute">
                      <div class="input-group">
                        <input name="datepicker"
                               class="form-control dp-hidden"
                               ngbDatepicker
                               #datepicker="ngbDatepicker"
                               [autoClose]="'outside'"
                               (dateSelect)="onDateSelection($event)"
                               [displayMonths]="2"
                               [dayTemplate]="t"
                               outsideDays="hidden"
                               [startDate]="startDate!"
                               tabindex="-1">
                        <ng-template #t let-date let-focused="focused">
                          <span class="custom-day"
                                [class.focused]="focused"
                                [class.range]="isRange(date)"
                                [class.faded]="isHovered(date) || isInside(date)"
                                (mouseenter)="hoveredDate = date"
                                (mouseleave)="hoveredDate = null">
                            {{ date.day }}
                          </span>
                        </ng-template>
                      </div>
                    </div>
                    <div class="input-group">
                      <input #dpFromDate
                             class=" form-control" placeholder="yyyy-mm-dd"
                             name="dpFromDate"
                             [value]="formatter.format(startDate)"
                             (input)="startDate = validateInput(startDate, dpFromDate.value)">
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <fa-icon [icon]="faCal" (click)="datepicker.toggle()"></fa-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 pr-1">
                    <div class="input-group">
                      <input #dpToDate
                             class=" form-control" placeholder="yyyy-mm-dd"
                             name="dpToDate"
                             [value]="formatter.format(endDate)"
                             (input)="endDate = validateInput(endDate, dpToDate.value)">
                      <div class="input-group-append">
                          <span class="input-group-text">
                            <fa-icon [icon]="faCal" (click)="datepicker.toggle()"></fa-icon>
                          </span>
                      </div>
                    </div>
                  </div>
                    <div class="col-md-5 pr-1">
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Promo Code" [(ngModel)]="promotion.code" name="code">
                      </div>
                    </div>
                    <div class="col-md-5 pr-1">
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Description" [(ngModel)]="promotion.text" name="text">
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 pr-1">
                        <div class="row">
                        <div class="col-md-2 pr-1">
                            <div class="form-group">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check"  [(ngModel)]="promotion.active" name="active">
                                    <label class="form-check-label" for="active">Active</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 pr-1">
                            <div class="form-group">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check" [(ngModel)]="promotion.canBePaired" name="paired">
                                    <label class="form-check-label" for="paired">Paired</label>
                                </div>
                            </div>
                        </div>                  
                        <div class="col-md-2 pr-1">
                            <div class="form-group">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check" [(ngModel)]="promotion.allProducts" name="all">
                                    <label class="form-check-label" for="all">All Products</label>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
                <h6>Promotion Terms</h6>
                <div class="row">
                    <div class="col-md-5 pr-1">
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="Type of Discount" [(ngModel)]="promotion.terms.discountType" name="termsType" >
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <fa-icon [icon]="faInfoCircle" ngbTooltip="How will the discount be applied (Percent, amount, ect)"></fa-icon>
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5 pr-1">
                        <div class="input-group">
                          <input type="number" class="form-control" placeholder="Amount" [(ngModel)]="promotion.terms.amount" name="termsAmount">
                          <div class="input-group-append">
                            <span class="input-group-text">
                                <fa-icon [icon]="faInfoCircle" ngbTooltip="The amount of discount applied to the products"></fa-icon>
                            </span>
                          </div>
                        </div>
                    </div>
                    <div class="col-md-5 pr-1">
                        <div class="input-group">
                            <input type="number" class="form-control" placeholder="Amount to Qualify" [(ngModel)]="promotion.terms.qualifyingAmount" name="termsQual">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <fa-icon [icon]="faInfoCircle" ngbTooltip="The amount of the order to Qualify for this Promotion"></fa-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 pr-1">
                        <div class="input-group">
                            <input type="number" class="form-control" placeholder="Total Uses" [(ngModel)]="promotion.terms.maxUses" name="termsTotal">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <fa-icon [icon]="faInfoCircle" ngbTooltip="How many times can this code be used by all users"></fa-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 pr-1">
                        <div class="input-group">
                            <input type="number" class="form-control" placeholder="Total Uses Per User" [(ngModel)]="promotion.terms.maxUsesPerCustomer" name="termsTotalUser">
                            <div class="input-group-append">
                                <span class="input-group-text">    
                                    <fa-icon [icon]="faInfoCircle" ngbTooltip="How many times can this code be used per user"></fa-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!promotion.allProducts" class="col-md-3 pr-1">
                    <button type="button" class="btn btn-round btn-default" (click)="selectProducts()">Add Products</button>
                </div>
                <div class="row">
                  <div class="col-md-4 pr-5">
                    <h4>Promotion Image</h4>
                    <div *ngIf="promotion.image?.filePath; else fileUpload" (click)="editPromoImage()">
                      <img class="border-gray" src="{{promotion.image.filePath}}" alt="...">
                    </div>
                    <ng-template #fileUpload>
                      <app-file-dnd [images]="uploadedFile" (uploadedFiles)="onValueChange($event)"></app-file-dnd>
                      <div *ngIf="image">
                          <button type="button" class="btn btn-round btn-default" (click)="cancelEditLogo()">Cancel</button>
                      </div>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf></div>
                <div class="col-md-2 pr-1">
                    <button type="submit" class="btn btn-round btn-default btn-simple">{{getTitle()}}</button>
                </div>
            </div>  
          </div>
        </div> 
    </div>
  </form>
</div>
<div *ngIf="showProductSearch" class="col-md-4">
    <app-product-search (selected)="addProducts($event)"></app-product-search>
</div>
<div *ngIf="products.length > 0" class="col-md-8">
    <div class="card">
<div class="card-header">
    <h5 class="title">Products</h5>
  </div>
  <div class="card-body">
    <div class="row">
        <table class="table">
            <thead class="text-primary">
                <tr>
                  <th scope="col"><input class="form-check" type="checkbox" (change)="selectAll()" [checked]="selectedAll"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let product of products">
                    <td><input class="form-check" type="checkbox" value="{{product.name}}" [(ngModel)]="product.selected" (change)="checkIfAllSelected()"></td>
                    <td (click)="editProduct(product.id)">
                      <img [src]="getImageUrl(product)" class="mr-2" style="width: 20px">
                      <ngb-highlight [result]="product.name"></ngb-highlight>
                    </td>
                    <td><ngb-highlight [result]="product.description"></ngb-highlight></td>
                  </tr>
        </table>
        <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="products.length" [(page)]="page" [pageSize]="pageSize" (pageChange)="search()">
            </ngb-pagination>
          
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="search()">
              <option [ngValue]="5">5 items per page</option>
              <option [ngValue]="10">10 items per page</option>
              <option [ngValue]="25">25 items per page</option>
            </select>
        </div>
  </div>
</div>
</div>
</div>
</div>
