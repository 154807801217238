<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute bg-primary fixed-top">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <span>CONTACT US: support@mobiecom.com</span>
        </div>
        <div class="collapse navbar-collapse justify-content-end">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div *ngIf="companies.length > 1">
                <select class="custom-select" (change)="selectCompany($event)">
                  <option value="0" [disabled]="true">Select Company</option>
                  <option *ngFor="let company of companies$ | async" value={{company}}>{{company.name}}</option>
                </select>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/dashboard']">
                <fa-icon class="admin-icons" [icon]="faBell"></fa-icon>
                <p>
                  <span class="d-lg-none d-md-block">Stats</span>
                </p>
              </a>
            </li>
            <li class="nav-item" ngbDropdown>
              <a class="nav-link" id="userdd" ngbDropdownToggle>
                <fa-icon class="admin-icons" [icon]="faUserCircle"></fa-icon>
                <p>
                  <span class="d-lg-none d-md-block">User Actions</span>
                </p>
              </a>
              <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="userdd">
                <a class="dropdown-item" [routerLink]="['/profile', profileId]">Edit Profile</a>
                <a class="dropdown-item" [routerLink]="['/settings']">Settings</a>
                <a class="dropdown-item" (click)="logout()">Log Out</a>
              </div>
            </li>
          </ul>
        </div>
    </div>
</nav>

