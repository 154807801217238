<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"> Products</h4>
        </div>
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group no-border">
                  <input class="form-control" type="text" placeholder="Search Products" (keyup)="applyFilter($event)">
                </div>
              </div>
              <div class="col-md-8 float-right pl-5" >
                <div class="form-group">
                  <button type="button" class="btn btn-round btn-primary" (click)="addProduct()">
                    <fa-icon class="admin-icons" [icon]="faPlus"></fa-icon>
                    Add Product
                  </button>
              </div>
            </div>      
          </div>            
          </form>
          <div *ngIf="products$ | async" class="table-responsive">
            <table class="table">
                <thead class="text-primary">
                    <tr>
                      <th scope="col"><input class="form-check" type="checkbox" (change)="selectAll()" [checked]="selectedAll"></th>
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Category</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Customer Rating</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let product of products">
                        <td><input class="form-check" type="checkbox" value="{{product.name}}" [(ngModel)]="product.selected" (change)="checkIfAllSelected()"></td>
                        <td (click)="editProduct(product.id)">
                          <img [src]="getImageUrl(product)" class="mr-2" style="width: 20px">
                          <ngb-highlight [result]="product.name"></ngb-highlight>
                        </td>
                        <td><ngb-highlight [result]="product.description"></ngb-highlight></td>
                        <td><ngb-highlight [result]="product.categories[0].name"></ngb-highlight></td>
                        <td><ngb-highlight [result]="getQuantity(product).toString()"></ngb-highlight></td>
                        <td><ngb-rating [readonly]="true" [rate]="product.rating" [max]="5"></ngb-rating></td>
                      </tr>
            </table>
            <div *ngIf="products.length" class="d-flex justify-content-between p-2">
                <ngb-pagination [collectionSize]="products.length" [(page)]="page" [pageSize]="pageSize" (pageChange)="search()">
                </ngb-pagination>
              
                <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="search()">
                  <option [ngValue]="5">5 items per page</option>
                  <option [ngValue]="10">10 items per page</option>
                  <option [ngValue]="25">25 items per page</option>
                </select>
              </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
