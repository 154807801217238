import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CustomerService } from "../service/customer.service";
import * as CustomerActions from "../actions/customer.actions";
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";

@Injectable()
export class CustomerEffects {

    constructor(private actions$: Actions, private customerService: CustomerService){ }

    loadCustomers$ = createEffect(() =>
    this.actions$.pipe(
        ofType(CustomerActions.loadCustomers),
        switchMap((payload: { query: String, page: number, size: number, companyId: string}) => 
            this.customerService.searchCustomers(payload.query, payload.page, payload.size,'lastName', 'asc', payload.companyId).pipe(
                map(customers =>
                    CustomerActions.loadCustomersSuccess({ customers })
                ),
                catchError((error) => {
                    console.log(error)
                    return of(CustomerActions.loadCustomersFailure())
                }
                )
            )
        )
    )
)
}