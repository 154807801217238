import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-designer',
  templateUrl: './designer.component.html',
  styleUrls: [   
    './designer.component.scss'
  ],
})
export class DesignerComponent implements OnInit, OnChanges {


  constructor(){}

  ngOnInit(): void {
  }

  ngOnChanges(changes: any) {
    
  }

  action(event: any){
    
  }
}
