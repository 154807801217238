<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"> Promotions</h4>
        </div>
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group no-border">
                            <input class="form-control" type="text" placeholder="Search Promotions" (keyup)="applyFilter($event)">
                        </div>
                    </div>
                    <div class="col-md-2 float-right pr-5" >
                        <button type="button" class="btn btn-round btn-primary" (click)="addPromotion()">
                        <fa-icon class="admin-icons" [icon]="faPlus"></fa-icon>
                            Add Promotion
                        </button>
                    </div>
                </div>                  
              </form>
          <div *ngIf="promotions$ | async" class="table-responsive">
              
              
            <table class="table">
                <thead class="text-primary">
                    <tr>
                      <th scope="col"><input class="form-check" type="checkbox" (change)="selectAll()" [checked]="selectedAll"></th>
                      <th scope="col">Code</th>
                      <th scope="col">Start</th>
                      <th scope="col">End</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let promo of promotions">
                        <td><input class="form-check" type="checkbox" value="{{promo.id}}" [(ngModel)]="promo.selected" (change)="checkIfAllSelected()"></td>
                        <td (click)="editPromo(promo.id)">
                          <ngb-highlight [result]="promo.code"></ngb-highlight>
                        </td>
                        <td><ngb-highlight [result]="promo.startDate | date"></ngb-highlight></td>
                        <td><ngb-highlight [result]="promo.endDate | date"></ngb-highlight></td>
                      </tr>
            </table>
            <div class="d-flex justify-content-between p-2">
                <ngb-pagination [collectionSize]="promotions.length" [(page)]="page" [pageSize]="pageSize" (pageChange)="search()">
                </ngb-pagination>
              
                <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="search()">
                  <option [ngValue]="5">5 items per page</option>
                  <option [ngValue]="10">10 items per page</option>
                  <option [ngValue]="25">25 items per page</option>
                </select>
              </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>