import { Component, OnInit } from '@angular/core';
import { Product, ProductVariant, Image, MonetaryAmount } from 'src/app/model/product.model';
import { OptionGroup, Option } from 'src/app/model/option-group.model';
import { Category, CategoryNode } from 'src/app/model/category.model';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Inventory } from 'src/app/model/inventory.model';
import { v4 as uuidv4 } from 'uuid';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { filter, map, skipUntil, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState, selectCategories, selectCategoryTree, selectCurrentCompany, selectProduct, selectProducts } from 'src/app/reducers';
import { createProduct, loadProduct, updateProduct } from 'src/app/actions/products.actions';
import { loadCategories, loadCategoryTree } from 'src/app/actions/category.actions';
import { faInfoCircle, faMinusCircle, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { add, isEqual } from 'lodash';
import { FileHandle } from '../file-dnd/dnd.directive';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-product-create-edit',
  templateUrl: './product-create-edit.component.html',
  styleUrls: ['./product-create-edit.component.scss']
})
export class ProductCreateEditComponent implements OnInit {

  product$: Observable<Product>;
  
  product = new Product();
  categories$: Observable<TreeviewItem[]>;
  files =  new Array<File>();
  rootCategory: String;
  showVariants: boolean = false;

  faPlus = faPlus;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faInfoCircle = faInfoCircle;

  treeViewConfig: TreeviewConfig = {
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    hasDivider: true,
    maxHeight: 400
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'fileName',
    textField: 'fileName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    noDataAvailablePlaceholderText: 'Please Upload an Image',
    itemsShowLimit: 10,
    allowSearchFilter: false
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '13rem',
    minHeight: '3rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter description here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['subscript', 'superscript', 'strikeThrough'],
      ['fontSize', 'insertImage', 'insertVideo', 'customClasses'],
    ],
  };

  companyId: string;
  currency: string;
  uploadedFiles = new Array<FileHandle>();
  images = new Array<Image>();

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.store.select(selectCurrentCompany).subscribe(company => {
      this.companyId = company.id;
      this.store.dispatch(loadCategoryTree({companyId: this.companyId}));
      this.store.dispatch(loadCategories({companyId: this.companyId}));
      this.currency = company.currency;
    });
    
    this.product.variants = new Array<ProductVariant>();
    this.product.tags = new Array<String>();
    this.product.optionGroups = new Array<OptionGroup>();
    let variant = new ProductVariant();
    variant.id = uuidv4();
    let inventory = new Inventory();
    variant.inventory = new Array<Inventory>();
    variant.inventory.push(inventory);
    variant.images = new Array<Image>();
    variant.options = new Array<Option>();
    variant.cost = new MonetaryAmount();
    variant.cost.currency = this.currency;
    variant.saleAmount = new MonetaryAmount();
    variant.saleAmount.currency = this.currency;
    variant.amount = new MonetaryAmount();
    variant.amount.currency = this.currency;
    this.product.variants.push(variant);
    const id = this.route.snapshot.paramMap.get('id');
    if(id) {
      this.store.select(selectProduct, id).subscribe(p => {
        if(p) this.product = JSON.parse(JSON.stringify(p))
      });
      if (this.product.variants.length > 1){
        this.showVariants = true;
      }
      this.product.variants.forEach(v => v.images.forEach(i => {
        this.images.push(i);
        if(!this.uploadedFiles.find(f => f.file.name === i.fileName)){
          this.uploadedFiles.push(this.imageToFileHandle(i));
        }
      })
      );
    } 
    var selectedCats = JSON.parse(JSON.stringify(this.product.categories));
    this.categories$ = this.store.select(selectCategoryTree).pipe(
      map(cat => cat.map(c => this.mapCategoryNodeToTreeItem(c, selectedCats))))
  
  }

  imageToFileHandle(image: Image) : FileHandle{
    var file = new File([""], image.fileName);
    return {file: file, url: image.filePath };
  }


  createTreeItem(category: CategoryNode) : TreeviewItem {
    var treeItem = new TreeviewItem({
      text: category.name as string,
      value: category.id,
      disabled: false,
      collapsed: true,
      checked: false,
      children: new Array<TreeviewItem>()
    });
    return treeItem;
  }

  mapChildren(category: CategoryNode, tree: TreeviewItem, selected: Category[]) {
      category.children?.forEach(child => {
        var item = this.createTreeItem(child);
        
        if (!tree.children) {
          var children = new Array<TreeviewItem>()
          children.push(item)
          tree.children = children
        } else {
          tree.children.push(item)
        }
        if (selected) {
          item.checked = selected.some(cat => cat.id === item.value);
        }
        if (child.children) {
          this.mapChildren(child, item, selected);
        }
      })

  }

  mapCategoryNodeToTreeItem(category: CategoryNode, selected: Category[]) : TreeviewItem {
    var treeItem = this.createTreeItem(category);
    if (category.children) {
      this.mapChildren(category, treeItem, selected);
    }
    return treeItem;
  }

  onSelectedChanged(event: String[]){
    this.store.select(selectCategories).subscribe(cat => {
        var filtered = cat.filter(c => event.includes(c.id));
        var selected = new Array<Category>();
        filtered.forEach(c => selected = selected.concat(this.getSelectedParents(c, cat)))
        this.product.categories = selected;
      }
    )
   
  }

  onItemSelect(item: any, variant: ProductVariant) {
    var image = this.images.find(i => i.fileName === item.fileName);
    if (image){
      variant.images.push(image);
    }
  }

  onItemDeSelect(item: any, variant: ProductVariant) {
    variant.images.forEach((val,index) => {
      if (val.fileName === item.fileName) {
        variant.images.splice(index,1);
      }
    });
  }

  onSelectAll(items: any[], variant: ProductVariant) {
    items.forEach(img => {
      var image = this.images.find(i => i.fileName === img.fileName);
      if (image){
        variant.images.push(image);
      }
    })
  }

  getSelectedParents(selected: Category, all: Category[]) : Category[]{
    var cats = new Array<Category>();
    cats.push(JSON.parse(JSON.stringify(selected)));
    while(selected.parentId != null) {
      var parent = all.find(c => c.id == selected.parentId);
      if(parent != undefined){
        cats.push(parent);
        selected = parent;
      } else {
        break;
      }
    }
    return cats;
  }

  getTitle() {
    return this.product.id?'Update':'Create'
  }

  submit() {
    this.product.variants.forEach(v => {
      if (v.cost?.amount == null) {
        v.cost = null;
      }
      if (v.saleAmount?.amount == null) {
        v.saleAmount = null;
      }
    })
    if (this.product.variants.length == 1) {
      this.product.variants[0].images = this.images;
    }
    if (!this.product.id) {
      this.store.dispatch(createProduct({product: this.product, files: this.files, companyId: this.companyId}));
    } else {
      this.store.dispatch(updateProduct({product: this.product, files: this.files, companyId: this.companyId}));
    }
  }

  currencyInputChanged(value: any) {
    var num = value.replace(/[$,]/g, "");
    var amount = new MonetaryAmount();
    amount.amount = Number(num);
    amount.currency = this.currency
    return amount;
  }

  setProfileImage(event: any) {
    var image = this.images.find(i => i.fileName === event);
    if (image){
      this.product.profileImage = image;
    }
  }

  onValueChange(file: FileHandle[]) {
    file.forEach((f, i) => {
      if(!this.files.includes(f.file)) {
        const reader = new FileReader();

        reader.readAsDataURL(f.file);
        reader.onload = () => {
          this.files.push(f.file);
          var imageArr = new Array<Image>();
          this.files.forEach((f,i) => {
            var image = new Image();
            image.fileName = f.name;
            image.order = i;
            imageArr.push(image);
          })
          this.images = imageArr;
          this.uploadedFiles.push(f);
        };
      }
    });
  }

  addOptionGroup() {
    let og = new OptionGroup();
    og.id = uuidv4();
    og.options = new Array<Option>();
    if (this.product.optionGroups.length === 0) {
      this.product.optionGroups = new Array<OptionGroup>();
    }
    this.product.optionGroups.push(og);
    this.showVariants = true;
  }

  removeOptionGroup(og: OptionGroup){
    if(og.options.length > 0){
      og.options.forEach(o => this.removeOption(o));
    }
    let index = this.product.optionGroups.findIndex((x) => x.id === og.id);
    this.product.optionGroups.splice(index, 1);
  }

  hasOptions(){
    return this.product.optionGroups[0]?.options?.length > 0;
  }

  removeOption(event: Option): void {
    var optionGroup = this.product.optionGroups.find((og) => og.id === event.groupId) as OptionGroup;
    if (optionGroup) {
      this.product.variants
        .filter((variant) => variant.options.some((opt) => opt.id === event.id))
        .forEach((variant) => this.deleteVariant(variant));
    }
    if (optionGroup.options.length === 0) {
      let index = this.product.optionGroups.findIndex((x) => x.id === optionGroup.id);
      this.product.optionGroups.splice(index, 1);
      this.generateUniqueVariants();
    }
    if(this.product.optionGroups.length === 0) {
      this.showVariants = false;
    }
  }

  addOption(event: Option): void {
    var optionGroup = this.product.optionGroups.find((og) => og.id === event.groupId) as OptionGroup;
    this.addVariants(optionGroup);
  }


  deleteVariant(variant: ProductVariant) {
    
    if (this.product.variants.length > 1) {
      let index = this.product.variants.findIndex((x) => x.id === variant.id);
      this.product.variants.splice(index, 1);
    } else {
      this.product.variants[0].options = new Array<Option>();
    }
  }

  addVariants(optionGroup: OptionGroup) {
    optionGroup.options.forEach((o) => (o.groupId = optionGroup.id));
    if (!this.product.optionGroups.some((og) => og.id === optionGroup.id)) {
      this.product.optionGroups.push(optionGroup);
      this.generateUniqueVariants();
    } else {
      this.generateUniqueVariants();
    }
  }

  private generateUniqueVariants() {
    var arr = new Array<Array<Option>>();
    this.product.optionGroups.forEach((og) => {
      og.options.forEach((o) => (o.groupId = og.id));
      arr.push(og.options);
    });
    var unique = this.allPossibleCases(arr);
    const variant = this.product.variants[0];
    variant.options = new Array<Option>();
    unique.forEach((x, index) => {
      if (Array.isArray(x)) {
        var newArr = new Array<any>();
        if (Array.isArray(x[1])) {
          newArr.push(x[0]);
          x[1].forEach(e => newArr.push(e));
        } else {
          newArr = x;
        }
        if (index == 0) {
          newArr.forEach((opt) => variant.options.push(opt));
        } else {
          var options = new Array<String>();
          newArr.forEach((opt) => options.push(opt.id));
          var exists = false;
          this.product.variants.forEach(v => {
            var vOpts = new Array<String>();
            v.options.forEach(o => vOpts.push(o.id));
            if (isEqual(vOpts, options)) {
              exists = true;
            } 
          });
        
          if(!exists) {
            var added = false;
            this.product.variants.forEach(v => {
              if (v.options.length != newArr.length) {
                if (v.options.every(o => newArr.includes(o))){
                  newArr.forEach(i => {
                    if (!v.options.includes(i)) {
                      v.options.push(i);
                      added = true;
                    }
                  });
                }
              } 
            })
            if(!added){
              let v = new ProductVariant();
              v.images = new Array<Image>();
              v.inventory = new Array<Inventory>();
              v.inventory.push(new Inventory());
              v.showSelect = false;
              v.options = new Array<Option>();
              v.amount = variant.amount;
              v.cost = variant.cost;
              v.saleAmount = variant.saleAmount;
              v.upc = variant.upc;
              newArr.forEach((opt) => v.options.push(opt));
              v.id = uuidv4();
              this.product.variants.push(v);
            }
          }
        }
      } else {
        var variantExists = false;
        this.product.variants.forEach((variant) => {
          if (!variant.options) {
            variant.options = new Array<Option>();
          }
          if (!variant.options.some((o) => o.groupId === x.groupId)) {
            variantExists = true;
            variant.options.push(x);
          }
          if (variant.options.some((o) => o.id === x.id)) {
            variantExists = true;
          }
        });
        if (!variantExists) {
          let v = new ProductVariant();
          v.images = new Array<Image>();
          v.inventory = new Array<Inventory>();
          v.inventory.push(new Inventory());
          v.showSelect = false;
          v.options = new Array<Option>();
          v.options.push(x);
          v.amount = variant.amount;
          v.cost = variant.cost;
          v.saleAmount = variant.saleAmount;
          v.upc = variant.upc;
          v.id = uuidv4();
          this.product.variants.push(v);
        }
      }
    });
  }

  allPossibleCases(arr: Array<any>): Array<any> {
    if (arr.length === 0) {
      return [];
    } else if (arr.length === 1) {
      return arr[0];
    } else {
      var result = [];
      var allCasesOfRest = this.allPossibleCases(arr.slice(1)); // recur with the rest of array
      for (var c in allCasesOfRest) {
        for (var i = 0; i < arr[0].length; i++) {
          result.push([arr[0][i], allCasesOfRest[c]]);
        }
      }
      return result;
    }
  }

}
