import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadOrders } from 'src/app/actions/order.actions';
import { Order } from 'src/app/model/order.model';
import { AppState, selectCurrentCompany, selectOrderCount, selectOrders } from 'src/app/reducers';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  constructor(private route: Router, private store: Store<AppState>) { }

  orders$: Observable<Order[]>;
  orders = new Array<Order>();
  pageSize = 10;
  page = 0;
  companyId: string;
  orderSize = 0;

  ngOnInit(): void {
    if (this.page != 0){
      this.page =- 1;
    }
    this.store.select(selectCurrentCompany).subscribe(company => {
      this.companyId = company.id;
      this.store.dispatch(loadOrders({companyId: company.id, query: '', page: this.page, size: this.pageSize}));
    });
    this.orders$ = this.store.select(selectOrders);
    this.store.select(selectOrderCount).subscribe(size => this.orderSize = size);
    this.orders$.subscribe(o => this.orders = JSON.parse(JSON.stringify(o)));
  }

  viewOrder(id: String) {
    this.route.navigate(['/orders/', id])
  }

  search(){
    this.store.dispatch(loadOrders({companyId: this.companyId, query: '', page: this.page-1, size: this.pageSize}));
  }
  

}
