import { Injectable, Optional } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthService, OAuthStorage } from "angular-oauth2-oidc";

@Injectable()
export class TenantInterceptor implements HttpInterceptor {

    constructor(private errorHandler: OAuthResourceServerErrorHandler, private authStorage: OAuthStorage, @Optional() private moduleConfig: OAuthModuleConfig){}

    tokenHelper = new JwtHelperService();

    private checkUrl(url: string): boolean {
        let found = this.moduleConfig.resourceServer.allowedUrls?.find(u => url.startsWith(u));
        return !!found;
    }

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        let url = req.url.toLowerCase();
        let token = this.authStorage.getItem('access_token');
        if (!this.moduleConfig) return next.handle(req);
        if (!this.moduleConfig.resourceServer) return next.handle(req);
        if (!this.moduleConfig.resourceServer.allowedUrls) return next.handle(req);
        if (!this.checkUrl(url)) return next.handle(req);
        
        let decoded = this.tokenHelper.decodeToken(token!);
        let companyId = req.params.get('companyId');
        let companies = decoded.company;
        
        if(companies.length > 0) {
            if (companyId) {
                var index = companies.indexOf(companyId);
                if (index > -1) {
                    req = req.clone({
                        headers: req.headers.set('Authorization', 'bearer ' + token).set('X-TenantID', companyId)
                    });
                }
            } else {
                req = req.clone({
                    headers: req.headers.set('Authorization', 'bearer ' + token).set('X-TenantID', companies[0])
                });
            }
        }
        return next.handle(req);
    }

}
