import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Tracking } from 'src/app/model/order.model';

@Component({
  selector: 'app-add-edit-tracking',
  templateUrl: './add-edit-tracking.component.html',
  styleUrls: ['./add-edit-tracking.component.scss']
})
export class AddEditTrackingComponent implements OnInit {

  @Input() public tracking: Tracking;

  constructor(public modal: NgbActiveModal) { }
  

  ngOnInit(): void {
    if (this.tracking == undefined) {
      this.tracking = new Tracking();
    }
  }

  setShipper(shipper: string){
    this.tracking.vendor = shipper;
  }

  updateTracking(){
    this.modal.close(this.tracking);
  }

}