import { Action, createReducer, on } from "@ngrx/store";
import { User } from "../model/user.model";
import * as CustomerActions from "../actions/customer.actions";

export interface CustomerState {
    customers: User[];
    activePage: number;
    totalPages: number;
    totalCustomers: number;
}

export const initialCustomerState = {
    customers: new Array<User>(),
    activePage: 0,
    totalPages: 0,
    totalCustomers: 0
}

const reducer = createReducer(
    initialCustomerState,
    on(CustomerActions.loadCustomersSuccess,
        (state, { customers }) => ({
            ...state, customers: customers.content, activePage: customers.number, totalPages: customers.totalPages, totalCustomers: customers.totalElements
        })
    ),
    
)

export function customerReducer(state: CustomerState | undefined, action: Action) {
    return reducer(state, action);
}