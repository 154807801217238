import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AppState } from "../reducers";
import { PromotionService } from "../service/promotion.service";
import * as PromotionsActions from "../actions/promotions.actions";
import { Promotion } from "../model/promotion.model";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class PromotionsEffects {
    
    constructor(private actions$: Actions, private promotionService: PromotionService, private store: Store<AppState>, private router: Router, private toastr: ToastrService){}

    searchPromotions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromotionsActions.loadPromos),
            switchMap((payload: { query: String, page: number, size: number, companyId: String}) => {
                return this.promotionService.search(payload.query, payload.page, payload.size, 'id', 'asc', payload.companyId).pipe(
                    map(promotions => PromotionsActions.loadPromosSuccess({ promotions })
                    ),
                    catchError(() => of(PromotionsActions.loadPromosFailure())
                    )
                    );
                }
            )
        )
    )

    createPromotion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromotionsActions.createPromo),
            switchMap((payload: { promotion: Promotion, companyId: String, file: File}) => {
                return this.promotionService.create(payload.promotion, payload.companyId, payload.file).pipe(
                    map(promotion => PromotionsActions.createPromoSuccess({ promotion })
                    ),
                    catchError(() => of(PromotionsActions.createPromoFailure()))
                )
            })
        )
    )

    updatePromotion$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromotionsActions.updatePromo),
            switchMap((payload: { promotion: Promotion, companyId: String, file: File}) => {
                return this.promotionService.update(payload.promotion, payload.companyId, payload.file).pipe(
                    map(promotion => PromotionsActions.updatePromoSuccess({ promotion })
                    ),
                    catchError(() => of(PromotionsActions.updatePromoFailure()))
                )
            })
        )
    )

    promoCreateSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromotionsActions.createPromoSuccess),
            tap(() => {
                this.router.navigate(['/promotions/list']);
                this.toastr.success('Promotion Created Sucessfully');
            })
        ), { dispatch: false }
    )

    promoUpdateSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromotionsActions.updatePromoSuccess),
            tap(() => {
                this.router.navigate(['/promotions/list']);
                this.toastr.success('Promotion Updated Sucessfully');
            })
        ), { dispatch: false }
    )

    promoCreateFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromotionsActions.createPromoFailure),
            tap(() => {
                this.toastr.error('Failed to Create Promotion');
            })
        ), { dispatch: false }
    )

    promoUpdateFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PromotionsActions.updatePromoFailure),
            tap(() => {
                this.toastr.error('Failed to Update Promotion');
            })
        ), { dispatch: false }
    )
}