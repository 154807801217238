<div class="container" appDnd (files)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
    <fa-icon class="icon" [icon]="faCloudUploadAlt"></fa-icon>
    <h6>Drag & drop images here</h6>
    <h6>or</h6>
    <label class="upload-button" for="fileDropRef">Select Files</label>
  </div>
<!--   <div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
        <fa-icon [icon]="faFile" width="45px" alt="file"></fa-icon>
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size">
          {{ formatBytes(file?.size) }}
        </p>
        <div class="progress-cont">
            <div class="progress" [style.width]="file?.progress + '%'">
            </div>
          </div>
      </div>
      <fa-icon [icon]="faTrashAlt" class="delete" width="20px" (click)="deleteFile(i)"></fa-icon>
    </div>
  </div> -->
  <div *ngIf="showImages && images && images.length > 0" class="files-list">
    <div class="single-file" *ngFor="let image of images; let i = index">
      <div class="info row">
        <img class="pl-1" [src]="image?.url" style="width: 50px; height: 50px;"/>
        <h4 class="name">
          {{ image?.file.name }}
        </h4>
        <div class="radio">
          <label>
            <input type="radio" name="optionsRadios" (change)="selectProfileImage(image?.file.name)" [checked]="isSelected(image?.file.name)">
            Profile Image
          </label>
        </div>
      </div>
      <fa-icon [icon]="faTrashAlt" class="delete" width="20px" (click)="removeImage(image)"></fa-icon>
    </div>
  </div>