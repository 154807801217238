import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadPromos } from 'src/app/actions/promotions.actions';
import { Promotion } from 'src/app/model/promotion.model';
import { AppState, selectCurrentCompany, selectSearchResultPromos } from 'src/app/reducers';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

  companyId: string;
  promotions$: Observable<Promotion[]>;
  promotions = new Array<Promotion>();
  selectedAll: any;
  page = 1;
  pageSize = 10;

  faPlus = faPlus;

  constructor(private route: Router, private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.select(selectCurrentCompany).subscribe(company => this.companyId = company.id);
    
    this.store.dispatch(loadPromos({query: '', page: 0, size: this.pageSize, companyId: this.companyId}));
    this.promotions$ = this.store.select(selectSearchResultPromos,0)
    this.promotions$.subscribe(promos => this.promotions = JSON.parse(JSON.stringify(promos)));
  }

  addPromotion(){
    this.route.navigate(['/promotions/new'])
  }

  applyFilter(filterValue: any) {
    filterValue = filterValue.target.value.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
  }

  selectAll() {
    this.selectedAll = !this.selectedAll;

    this.promotions.forEach(promo => promo.selected = this.selectedAll)
  }
  checkIfAllSelected() {  
    var totalSelected =  0;
    this.promotions.forEach(promo => {
      if(promo.selected)totalSelected++;
    })
    this.selectedAll = totalSelected === this.promotions.length;

    return true;
  }

  search() {
   
  }

  editPromo(id: String) {
    this.route.navigate(['/promotions/', id])
  }

}
