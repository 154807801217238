import { Action, createReducer, on } from "@ngrx/store";
import { CompanyTemplate, Template } from "../model/template.model";
import * as TemplateActions from '../actions/template.actions';


export interface TemplateState {
    templates: Template[];
    companyTemplates: CompanyTemplate[];
}

export const initialTemplateState = {
    templates: new Array<Template>(),
    companyTemplates: new Array<CompanyTemplate>(),
}

const reducer = createReducer(
    initialTemplateState,
    on(TemplateActions.loadTemplatesSuccess,
        (state, { templates }) => ({
            ...state, templates: templates
        })
    ),
    on(TemplateActions.getCompanyTemplatesSuccess,
        (state, { templates }) => ({
            ...state, companyTemplates: templates.content
        })
    ),
    on(TemplateActions.updateTemplateSuccess,
        (state, { template }) => ({
            ...state, companyTemplates: updateCompanyTemplate(state.companyTemplates, template)
        })
    ),
    on(TemplateActions.applyTemplateToCompanySuccess,
        (state, { template }) => ({
            ...state, companyTemplates: mergeCompanyTemplates(state.companyTemplates, [template])
        })
    ),
    on(TemplateActions.updateTemplateImageSuccess,
        (state, { template }) => ({
            ...state, companyTemplates: updateCompanyTemplate(state.companyTemplates, template)
        })
    ),
)

function mergeCompanyTemplates(state: CompanyTemplate[], template: CompanyTemplate[]){
    let newProducts = [...state, ...template]
    const uniqueArray = newProducts.reduce((newArray: CompanyTemplate[], item) =>{
        if (newArray.find(i => i.id === item.id)){
            return newArray
        } else {
            return [...newArray, item]
        }
    }, [])
    return uniqueArray;
}

function updateCompanyTemplate(state: CompanyTemplate[], template: CompanyTemplate) {
    let newArray = [...state];
    newArray.forEach((s, i) => {
        if(s.id === template.id) {
            newArray[i] = template;
        }
    });
    return newArray;
}

export function templateReducer(state: TemplateState | undefined, action: Action) {
    return reducer(state, action);
}
