
import { Component, ViewChild, ElementRef, Output, EventEmitter, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { faCloudUploadAlt, faFile, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Image } from "src/app/model/product.model";
import { FileHandle } from "./dnd.directive";

@Component({
  selector: 'app-file-dnd',
  templateUrl: './file-dnd.component.html',
  styleUrls: ['./file-dnd.component.scss']
})
export class FileDndComponent {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @Input() images: any[];
  @Input() selectedProfileImage: any;
  @Input() showImages: boolean = true;

  files: any[] = [];

  @Output() uploadedFiles = new EventEmitter<any[]>();
  @Output() profileImage = new EventEmitter<any>();

  constructor(private sanitizer: DomSanitizer){}

  faCloudUploadAlt = faCloudUploadAlt;
  faFile = faFile;
  faTrashAlt = faTrashAlt;

  /**
   * on file drop handler
   */
  onFileDropped($event: any[]) {
    this.prepareFilesList($event);
    this.uploadedFiles.emit(this.files);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(target: any) {
    var element = target.currentTarget
    
    let files: FileHandle[] = [];
    for (const file of element.files){
      const url = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file)
      );
      files.push({ file, url });
    }
    this.prepareFilesList(files);
    this.uploadedFiles.emit(this.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
  }

  removeImage(image: Image) {
    let index = this.images.indexOf(image);
    this.files.splice(index, 1);
    this.images.splice(index, 1);
  }

  selectProfileImage(image: String) {
    this.profileImage.emit(image);
  }

  isSelected(fileName: any){
    return this.selectedProfileImage === fileName;
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
    //this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

}
