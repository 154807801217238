import { createAction, props } from "@ngrx/store";
import { Category, CategoryNode } from "../model/category.model";

export const loadCategoryTree = createAction('[Category] Category Tree Load', props<{companyId: string}>());
export const loadCategoryTreeSuccess = createAction('[Category] Category Tree Load Success', props<{categories: CategoryNode[]}>());
export const loadCategoryTreeFailure = createAction('[Category] Category Tree Load Failure');

export const loadCategories = createAction('[Cagegory] Category Load', props<{companyId: string}>());
export const loadCategoriesSuccess = createAction('[Cagegory] Category Load Success', props<{categories: Category[]}>());
export const loadCategoriesFailure = createAction('[Cagegory] Category Load Failure');

export const updateCategory = createAction('[Category] Update Category', props<{category: Category, companyId: string}>());
export const updateCategorySuccess = createAction('[Category] Update Category Success', props<{category: Category}>());
export const updateCategoryFailure = createAction('[Category] Update Category Failure');

export const createCategory = createAction('[Category] Create Category', props<{category: Category, companyId: string}>());
export const createCategorySuccess = createAction('[Category] Create Category Success', props<{category: Category}>());
export const createCategoryFailure = createAction('[Category] Create Category Failure');