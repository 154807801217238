import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { updateCompanyUser } from 'src/app/actions/user.actions';
import { CompanyUser } from 'src/app/model/user.model';
import { AppState, selectCurrentCompany, selectCurrentUser, selectUser } from 'src/app/reducers';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  companyUser: CompanyUser;
  companyUser$: Observable<CompanyUser | undefined>;
  companyId: string;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.companyUser$ = this.store.select(selectCurrentUser);
    this.companyUser$.subscribe(user => this.companyUser = JSON.parse(JSON.stringify(user)))
    this.store.select(selectCurrentCompany).subscribe(c => this.companyId = c.toString());
  }

  submit() {
    this.store.dispatch(updateCompanyUser({user: this.companyUser, companyId: this.companyId}))
  }

}

