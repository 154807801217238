import { Action, createReducer, on } from "@ngrx/store"
import { Order, Sales } from "../model/order.model"
import * as OrderActions from "../actions/order.actions"

export interface OrderState {
    orders: Order[];
    ytdSales: Sales[];
    mtdSales: Sales[];
    topProducts: Sales[];
    activePage: number;
    totalPages: number;
    totalOrders: number;
}

export const initialOrderState = {
    orders: new Array<Order>(),
    ytdSales: new Array<Sales>(),
    mtdSales: new Array<Sales>(),
    topProducts: new Array<Sales>(),
    activePage: 0,
    totalPages: 0,
    totalOrders: 0
}

const reducer = createReducer(
    initialOrderState,
    on(OrderActions.loadYTDSalesSuccess,
        (state, { sales }) => ({
            ...state, ytdSales: sales
        })
    ),
    on(OrderActions.loadMonthSalesSuccess,
        (state, { sales }) => ({
            ...state, mtdSales: sales
        })
    ),
    on(OrderActions.loadOrdersSuccess,
        (state, { orders }) => ({
            ...state, orders: orders.content, activePage: orders.number, totalPages: orders.totalPages, totalOrders: orders.totalElements
        })
    ),
    on(OrderActions.loadTopProductsSuccess,
        (state, { sales }) => ({
            ...state, topProducts: sales
        })
    ),
    on(OrderActions.addTrackingToOrderSuccess,
        (state, { order }) => ({
            ...state, orders: updateOrder(order, state.orders)
        })
    ), 
)

function updateOrder(order: Order, existingOrders: Order[]){
    var index = existingOrders.findIndex(o => o.id == order.id);
    if (index !== -1)
        existingOrders[index] = order;
    return existingOrders;
}

export function orderReducer(state: OrderState | undefined, action: Action) {
    return reducer(state, action);
}