<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
    <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">
              <h5 class="title">Edit Profile</h5>
            </div>
            <div class="card-body">
              <form (submit)="submit()">
                <div class="row">
                   <div class="col-md-4 px-1">
                     <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" placeholder="email" [(ngModel)]="companyUser.email" name="email">
                     </div>
                   </div>
                  <div class="col-md-5 pr-1">
                    <div class="form-group">
                      <label>First Name</label>
                      <input type="text" class="form-control" placeholder="First Name" [(ngModel)]="companyUser.firstName" name="name">
                    </div>
                  </div>
                  <div class="col-md-3 pl-1">
                    <div class="form-group">
                      <label for="lastName">Last Name</label>
                      <input name="lastName" type="text" class="form-control" placeholder="Last Name" [(ngModel)]="companyUser.lastName">
                    </div>
                  </div>
                  <div class="col-md-4 px-1">
                    <div class="form-group">
                      <label>Email</label>
                      <input type="email" class="form-control" placeholder="email" [(ngModel)]="companyUser.password" name="email">
                    </div>
                  </div>
                </div>
                <div class="row">
                </div>
                <div class="col-md-2 pr-1">
                    <button type="submit" class="btn btn-round btn-default btn-simple">Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
</div>
