import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers";
import { OrderService } from "../service/order.service";
import * as OrderActions from '../actions/order.actions';
import { catchError, map, switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { Tracking } from "../model/order.model";

@Injectable()
export class OrderEffects {
    
    constructor(private actions$: Actions, private orderService: OrderService, private store: Store<AppState>, private router: Router){}

    loadOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.loadOrders),
            switchMap((payload: { companyId: string, query: string, page: number, size: number}) => {
                return this.orderService.getOrders(payload.companyId, payload.query, payload.page, payload.size).pipe(
                    map(orders => OrderActions.loadOrdersSuccess({ orders })
                    ),
                    catchError(() => of(OrderActions.loadOrdersFailure())
                    )
                    );
                }
            )
        )
    )

    YTDSales$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.loadYTDSales),
            switchMap((payload: { companyId: string}) => {
                return this.orderService.getYTDSales(payload.companyId).pipe(
                    map(sales => OrderActions.loadYTDSalesSuccess({ sales })
                    ),
                    catchError(() => of(OrderActions.loadYTDSalesFailure())
                    )
                    );
                }
            )
        )
    )

    monthSales$ = createEffect(() =>
    this.actions$.pipe(
        ofType(OrderActions.loadMonthSales),
        switchMap((payload: { companyId: string, month: string}) => {
            return this.orderService.getMTDSales(payload.companyId, payload.month).pipe(
                map(sales => OrderActions.loadMonthSalesSuccess({ sales })
                ),
                catchError(() => of(OrderActions.loadMonthSalesFailure())
                )
                );
            }
        )
    )
)

    topProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.loadTopProducts),
            switchMap((payload: { companyId: string, daysBack: number}) => {
                return this.orderService.getTopProductsForPeriod(payload.companyId, payload.daysBack).pipe(
                    map(sales => OrderActions.loadTopProductsSuccess({ sales })
                    ),
                    catchError(() => of(OrderActions.loadTopProductsFailure())
                    )
                    );
                }
            )
        )
    )

    addTracking$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.addTrackingToOrder),
            switchMap((payload: { companyId: string, orderId: string, tracking: Tracking}) => {
                return this.orderService.addTracking(payload.companyId, payload.orderId, payload.tracking).pipe(
                    map(order => OrderActions.addTrackingToOrderSuccess({ order })
                    ),
                    catchError(() => of(OrderActions.addTrackingToOrderFailure()))
                )
            })
        )
    ) 


}