import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Store } from '@ngrx/store';
import { CreatePaymentMethodCardData, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { loadCompanies, loadCompany, updateCompany, updateCompanyLogo } from 'src/app/actions/company.actions';
import { Attribute, Company } from 'src/app/model/company.model';
import { Image } from 'src/app/model/product.model';
import { Address } from 'src/app/model/user.model';
import { AppState, selectCompany, selectCurrentCompany } from 'src/app/reducers';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  company$: Observable<Company>;
  company = new Company();
  logo = new Image();
  updatePayment = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '13rem',
    minHeight: '3rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Tell your customers about your company ...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['subscript', 'superscript', 'strikeThrough'],
      ['fontSize', 'insertImage', 'insertVideo', 'customClasses'],
    ],
  };

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: 'Montserrat, Helvetica Neue, Arial, sans-serif',
        fontSize: '16px',
        ':focus': {
          color: '#424770',
        },
        '::placeholder': {
          color: '#9BACC8',
        },
      },
      invalid: {
        color: '#fff',
        ':focus': {
          color: '#FA755A',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  constructor(private store: Store<AppState>, private stripeService: StripeService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.company$ = this.store.select(selectCurrentCompany);
      this.company$.subscribe(c => this.company = JSON.parse(JSON.stringify(c)));
      if (!this.company.address) {
        this.company.address = new Address();
      }
  }
    
  }

  saveCard(){
    const paymentMethod = {} as CreatePaymentMethodCardData;
    paymentMethod.card = this.card.element;
    paymentMethod.type = "card";
    this.stripeService.createPaymentMethod(paymentMethod)

    .subscribe((result) => {
      if (result.paymentMethod) {
       this.company.subscription.subscriptionPayment.paymentMethodId = result.paymentMethod.id;
       this.company.subscription.subscriptionPayment.last4 = result.paymentMethod.card?.last4;
       this.company.subscription.subscriptionPayment.type = result.paymentMethod.card?.brand;
      } else if (result.error) {
        console.log(result.error.message);
      }
    });
  }

  updatePay() {
    this.updatePayment = !this.updatePayment;
  }

  submit(company: Company){
    this.store.dispatch(updateCompany({company: company}));
  }

  changePlan(event: any){
    switch(event.target.value) {
      case "Basic": {
        this.company.subscription.subscriptionPayment.amount = 10;
        break;
      }
      case "Business": {
        this.company.subscription.subscriptionPayment.amount = 30;
        break;
      }
      default: {
        break;
      }
    }
  }

  setCurrency(value: string, event: any){
    this.company.currency = value;
    event.preventDefault();
  }

  setGateway(value: string, event: any){
    this.company.paymentGateway.provider = value;
    event.preventDefault();
  }

  readAttribute(value: string){
    if (this.company.paymentGateway.attributes != null ) {
      return this.company.paymentGateway.attributes.find(a => a.name === value)?.value;
    }
    return null;
  }

  setAttribute(name: string, value: string, secure: boolean){
    if (this.company.paymentGateway.attributes.some(a => a.name === name)){
      this.company.paymentGateway.attributes.find(a => a.name === name)!.value = value;
      this.company.paymentGateway.attributes.find(a => a.name === name)!.secured = secure;
    } else {
      var attribute = new Attribute();
      attribute.name = name;
      attribute.value = value;
      attribute.secured = secure;
      this.company.paymentGateway.attributes.push(attribute);
    }
  }

  currencyLabel(value: string) {
    switch (value) {
      case 'USD': {
        return 'Dollar';
      }
      case 'GBP': {
        return 'Pound';
      }
      case 'EUR': {
        return 'Euro';
      }
      default: {
        break;
      }
    }
    return '';
  }

  onValueChange(file: File[]) {
    file.forEach((f, i) => {
      const reader = new FileReader();

      reader.readAsDataURL(f);
      reader.onload = () => {
        var image = new Image();
        image.fileName = f.name;
        image.order = i;
        this.company.logo = image;
        this.store.dispatch(updateCompanyLogo({company: this.company, image: f}));
      };
    });
    this.company$ = this.store.select(selectCurrentCompany);
  }

  editLogo(){
    this.logo = this.company.logo;
    this.company.logo = new Image();
  }

  cancelEditLogo(){
    this.company.logo = this.logo;
  }

}
