import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompanyUser, User } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  getCompanyUsers(companyId: String) : Observable<CompanyUser[]> {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.httpClient.get<CompanyUser[]>(environment.companyUrl + '/users', { params });
  }

  getMyCompanyUser(companyId: String) : Observable<CompanyUser> {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.httpClient.get<CompanyUser>(environment.companyUrl + '/users/me', { params });
  }

  updateCompanyUser(companyUser: CompanyUser, companyId: String) : Observable<CompanyUser> {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.httpClient.put<CompanyUser>(environment.companyUrl + '/users/' + companyUser.id, companyUser, { params });
  }

  getCustomers(companyId: String) : Observable<User[]> {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.httpClient.get<User[]>(environment.userUrl, { params })
  }
}
