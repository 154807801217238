import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { updateCategory } from 'src/app/actions/category.actions';
import { Category } from 'src/app/model/category.model';
import { AppState, selectCategories } from 'src/app/reducers';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories$: Observable<Category[]>;
  page = 1;
  pageSize = 10;
  totalProducts: 0;
  selectedAll: any;

  constructor(private store: Store<AppState>,) { }

  ngOnInit(): void {
    this.categories$ = this.store.select(selectCategories);
  }

  editCategory(cat: Category) {
    this.store.dispatch(updateCategory({category: cat, companyId: ""}))
  }

  search(){

  }

  selectAll(){

  }

  checkIfAllSelected() {  
    var totalSelected =  0;
    
    return true;
  }

}
