import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Product, SearchResult } from '../model/product.model';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private httpClient: HttpClient) {}

  addProduct(product: Product, companyId: String, files?: File[]): Observable<Product> {
    const formData = new FormData();
    formData.append('product', new Blob([JSON.stringify(product)], { type: 'application/json'}));
    if (files) {
      files.forEach(file => formData.append('files', file));
    }
    let params = new HttpParams().set('companyId', String(companyId));
    return this.httpClient.post<Product>(
      environment.productUrl + '/product',
      formData, { params }
    );
  }

  updateProduct(product: Product, companyId: String, files?: File[]) {
    const formData = new FormData();
    formData.append('product', new Blob([JSON.stringify(product)], { type: 'application/json'}));
    if (files) {
      files.forEach(file => formData.append('files', file));
    }
    let params = new HttpParams().set('companyId', String(companyId));
    return this.httpClient.put<Product> (
      environment.productUrl + '/product/' + product.id,
      formData, { params }
    );
  }

  searchProducts(
    query: String,
    page: number = 0,
    size: number,
    sortColumn: String,
    sort: String,
    companyId: String
  ): Observable<SearchResult> {
    let params;
    let sortQuery = sortColumn + ',' + sort;
    if (query != undefined && query != '') {
      params = new HttpParams()
        .set('q', String(query))
        .set('page', String(page))
        .set('size', String(size))
        .set('sort', String(sortQuery))
        .set('companyId', String(companyId));
    } else {
      params = new HttpParams()
        .set('page', String(page))
        .set('size', String(size))
        .set('sort', String(sortQuery))
        .set('companyId', String(companyId));
    }
    return this.httpClient.get<SearchResult>(
      environment.productUrl + '/product/search',
      { params }
    );
  }

  getProduct(id: String, companyId: String): Observable<Product> {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.httpClient.get<Product>(environment.productUrl + '/product/' + id, { params });
  }

  getProducts(companyId: String): Observable<Product[]> {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.httpClient.get<Product[]>(environment.productUrl + '/product', { params });
  }

  delete(id: string, companyId: String) {
    let params = new HttpParams().set('companyId', String(companyId));
    this.httpClient.delete(environment.productUrl + '/product/' + id, { params }).pipe(
      catchError(error => {
        return throwError(error);
      })
    ).subscribe();
  }
}
