import { createAction, props } from "@ngrx/store";
import { Order, Sales, Tracking } from "../model/order.model";
import { SearchResult } from "../model/product.model";

export const loadYTDSales = createAction('[Sales] Load YTD Sales',props<{companyId: string}>());
export const loadYTDSalesSuccess = createAction('[Sales] Load YTD Sales Success', props<{sales: Sales[]}>());
export const loadYTDSalesFailure = createAction('[Sales] Load YTD Sales Failure');

export const loadMonthSales = createAction('[Sales] Load Monthly Sales',props<{companyId: string, month: string}>());
export const loadMonthSalesSuccess = createAction('[Sales] Load Monthly Sales Success', props<{sales: Sales[]}>());
export const loadMonthSalesFailure = createAction('[Sales] Load Monthly Sales Failure');

export const loadTopProducts = createAction('[Sales] Load Top Products',props<{companyId: string, daysBack: number}>());
export const loadTopProductsSuccess = createAction('[Sales] Load Top Products Success', props<{sales: Sales[]}>());
export const loadTopProductsFailure = createAction('[Sales] Load Top Products Failure');

export const loadOrders = createAction('[Orders] Load Orders', props<{query: string, page: number, size: number, companyId: string}>());
export const loadOrdersSuccess = createAction('[Orders] Load Orders Success', props<{orders: SearchResult}>());
export const loadOrdersFailure = createAction('[Orders] Load Orders Failure');

export const addTrackingToOrder = createAction('[Orders] Add Tracking',props<{companyId: string, orderId: string, tracking: Tracking}>());
export const addTrackingToOrderSuccess = createAction('[Orders] Add Tracking Success', props<{order: Order}>());
export const addTrackingToOrderFailure = createAction('[Orders] Add Tracking Failure');