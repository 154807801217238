import {
    Directive,
    Output,
    Input,
    EventEmitter,
    HostBinding,
    HostListener
  } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
  
  @Directive({
    selector: '[appDnd]'
  })
  export class DndDirective {
    @HostBinding('class.fileover') fileOver: boolean;
    @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();

    constructor(private sanitizer: DomSanitizer){}
  
    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt: any) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = true;
    }
  
    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;
    }
  
    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt: any) {
      evt.preventDefault();
      evt.stopPropagation();

      this.fileOver = false;
      let files: FileHandle[] = [];
      for (let i = 0; i < evt.dataTransfer.files.length; i++) {
        const file = evt.dataTransfer.files[i];
        const url = this.sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(file)
        );
        files.push({ file, url });
      }
      if (files.length > 0) {
        this.files.emit(files);
      }
    }
  }

  export interface FileHandle {
    file: File;
    url: SafeUrl;
  }