import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconDefinition, faArrowAltCircleRight, faInfoCircle, fas } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { updateTemplate, updateTemplateImage } from 'src/app/actions/template.actions';
import { CompanyTemplate, Template } from 'src/app/model/template.model';
import { CompanyUser } from 'src/app/model/user.model';
import { AppState, selectCompanyTemplate, selectCurrentCompany, selectProducts, selectTemplate, selectCurrentUser } from 'src/app/reducers';
import { WebsocketService } from 'src/app/service/websocket.service';
import { environment } from 'src/environments/environment';
import tinycolor from "tinycolor2";

@Component({
  selector: 'app-template-builder',
  templateUrl: './template-builder.component.html',
  styleUrls: ['./template-builder.component.scss', './devices.min.css']
})
export class TemplateBuilderComponent implements OnInit {
  @ViewChild('iframe', {static: false}) iframe: ElementRef;

  homeComponents: any[];
  comps: string[] = [];

  faArrowAltCircleRight = faArrowAltCircleRight;
  faInfoCircle = faInfoCircle;
  
  companyId: String;
  userId: String;
  template: CompanyTemplate;
  template$: Observable<CompanyTemplate | undefined>;
  baseTemplate$: Observable<Template | undefined>;
  userProfile$: Observable<CompanyUser | undefined>;
  ideUrl: SafeResourceUrl;
  sampleProductId: string;
  
  loading: boolean = true;
  updating: boolean = false;

  headerImage$: Observable<String>;
  loginImage$: Observable<String>;
  registerImage$: Observable<String>;
  iframeUrl: any;

  jsonpath = require("jsonpath")

  toolboxJson: any;

  constructor(private store: Store<AppState>, private route: ActivatedRoute, private websocketService: WebsocketService, private domSanitizer: DomSanitizer, private authStorage: OAuthStorage, private faLibrary: FaIconLibrary) {
    faLibrary.addIconPacks(fas);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data.type === 'ready'){
      this.loading = false;
    }
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.store.select(selectCurrentCompany).subscribe(c => this.companyId = c.id);
    this.store.select(selectProducts).subscribe(p => this.sampleProductId = p.products[0].id);
    if(id) {
      let token = this.authStorage.getItem('access_token');
      this.template$ = this.store.select(selectCompanyTemplate, id);
      this.template$.subscribe(t => {
        this.template = JSON.parse(JSON.stringify(t))
        let splashImage = this.jsonpath.query(Object(this.template.componentLayouts), '$.splash.image')
        this.setSplashImage(splashImage);
        this.baseTemplate$ = this.store.select(selectTemplate, t?.templateId!);
        if (!this.toolboxJson) {
          this.baseTemplate$.subscribe(bt => {
            this.toolboxJson = JSON.parse(JSON.parse(JSON.stringify(bt?.toolbox)));
            Object(this.toolboxJson).forEach((v: any) => {
              Object(v).options.forEach((opt: { value: any; attributes: string[]; type: string; options: any[]}) => {
                if (opt.type === 'drag-and-drop-list') {
                  opt.value = this.getDefaultValueForDnD(opt.attributes, opt.options);
                } else {
                  opt.value = this.getDefaultValueFromTemplate(opt.attributes)
                }
              });
            });
          });
        }
      });
      this.userProfile$ = this.store.select(selectCurrentUser);
      this.userProfile$.subscribe(profile => {
        this.iframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.appRunnerUrl + '?user=' + profile?.id! + '&companyId=' + this.companyId + '&templateId=' + id + '&token=' + token);
        this.userId = profile?.id!;
        this.websocketService.watch('/topic/' + this.companyId + '/' + this.userId + '/notify').subscribe((message) => this.updating = false);
      })
    }
  }

  drop(event: CdkDragDrop<string[]>, options: any[], components: any, attribute: string[]) {
    moveItemInArray(components, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      let result = new Map();
      for (let i of components) {
        let v = options[i];
        result.set(i, v);
      }

      var updatedComps = JSON.parse(JSON.stringify([...result.values()]));
      var update = JSON.parse(JSON.stringify(this.template));
      this.jsonpath.value(Object(update.componentLayouts), '$.' + attribute[0], updatedComps);
      this.updateTemplate(update);
    }
  }

  hasOptions(options: any) {
    return Object.keys(options).length > 0;
    
  }

  updateSplashDefaults() {
    let color = this.jsonpath.query(Object(this.template.componentLayouts), '$.splash.color')
    this.setSplashColor(color);
    let image = this.jsonpath.query(Object(this.template.componentLayouts), '$.splash.image')
    this.setSplashImage(image);
    
  }

  getDefaultValueFromTemplate(attributes: string[]){
    let val;
    for (let attribute of attributes) {
      if (attribute.startsWith('theme')) {
        val = this.jsonpath.query(Object(this.template.theme), '$.' + attribute.slice(6))
      } else if (attribute == 'splash.color') {
        val = this.jsonpath.query(Object(this.template.componentLayouts), '$.' + attribute)
        this.setSplashColor(val);
      } else if (attribute == 'splash.image') {
        val = this.jsonpath.query(Object(this.template.componentLayouts), '$.' + attribute)
        this.setSplashImage(val);
      } else {
        val = this.jsonpath.query(Object(this.template.componentLayouts), '$.' + attribute)
      }
      if (val) {
        break;
      }
    }
    return val[0];
  }

  returnZero() {
    return 0;
  }

  getDefaultValueForDnD(attributes: string[], options: any[]){
    let val;
    for (let attribute of attributes) {
      if (attribute.startsWith('theme')) {
        val = this.jsonpath.query(Object(this.template.theme), '$.' + attribute.slice(6))
      } else {
        val = this.jsonpath.query(Object(this.template.componentLayouts), '$.' + attribute)
      }
      if (val) {
        break;
      }
    }
    let result = new Map();
    for (let i of val[0]) {
      let index = this.getByValue(options, i);
      result.set(index, i);
    }
    return [...result.keys()];
  }

  getByValue(map: any[], searchValue: any) {
    const it = new Map(Object.entries(map));
    for (let [key, value] of it.entries()) {
      if (value === searchValue)
        return key;
    }
    return null;
  }

  getFaIcon(def: any[]): IconDefinition {
    return this.faLibrary.getIconDefinition(def[0], def[1])!;
  }

  changeRoute(path: String){
    if (path === 'splash') {
      this.showSplash(true);
    } else {
      this.showSplash(false);
      var newPath = path.replace("{productId}", this.sampleProductId);
      let message = {'command': 'route', 'path': newPath}
      this.iframe.nativeElement.contentWindow.postMessage(message, '*');
    }
  }

  showSplash(show: boolean){
    let message = {'command': 'show-splash', 'show': show}
    this.iframe.nativeElement.contentWindow.postMessage(message, '*');
  }

  setSplashColor(color: string){
    if (this.iframe){
      let message = {'command': 'set-splash', 'color': color}
      this.iframe.nativeElement.contentWindow.postMessage(message, '*');
    }
  }

  setSplashImage(url: string){
    if (this.toolboxJson) {
      this.updateToolboxValue("splash.image", url);
    }
    if (this.iframe) {
      let message = {'command': 'set-splash', 'url': url}
      this.iframe.nativeElement.contentWindow.postMessage(message, '*');
    }
  }

  updateTemplate(updatedTemplate: CompanyTemplate) {
    this.updating = true;
    this.store.dispatch(updateTemplate({template: updatedTemplate}));
  }

  changeOpt(attributes: string[], opt: string){
    var update = JSON.parse(JSON.stringify(this.template));
    attributes.forEach(attribute => {
      if (attribute.startsWith('theme')) {
        this.jsonpath.value(Object(update.theme), '$.' + attribute.slice(6), opt)
      } else {
        this.jsonpath.value(Object(update.componentLayouts), '$.' + attribute, opt)
      }
      this.updateToolboxValue(attribute, opt);
    });
    this.updateTemplate(update);
  }

  updateAttr(attributes: string[], event: any) {
    var current = this.getDefaultValueFromTemplate(attributes);
    //default icon if one is not set is fa-user-plus, need to skip setting.
    if (event === current || event === 'fa fa-user-plus') {
      return;
    }
    var update = JSON.parse(JSON.stringify(this.template));
    attributes.forEach(attribute => {
      if (attribute.startsWith('theme')) {
        this.jsonpath.value(Object(update.theme), '$.' + attribute.slice(6), event)
      } else {
        this.jsonpath.value(Object(update.componentLayouts), '$.' + attribute, event)
      }
      this.updateToolboxValue(attribute, event);
    });
    this.updateTemplate(update);
    if (attributes[0] == 'splash.color') {
      this.setSplashColor(event);
    }
    
  }

  updateToolboxValue(attribute: String, value: any) {
    let val = this.jsonpath.query(this.toolboxJson, '$..options[?(@.attributes[0]=="' + attribute + '")].value')
    if (Array.isArray(val) && val.length != 0) {
      this.jsonpath.value(this.toolboxJson, '$..options[?(@.attributes[0]=="' + attribute + '")].value', value)
    }
  }

  onFileSelectedAtt(event: any, attributes: string[]) {
    this.updating = true;
    const file:File = event.target.files[0];
    if (file) {
      attributes.forEach(attribute => {
        const section = attribute.split(".")
        const imagePath = section.length > 2 ? "/" + section[1] + "/" + section[2]: "/" + section[1];
        this.store.dispatch(updateTemplateImage({companyId: this.companyId, templateId: this.template.id, file: file, section: section[0], imagePath: imagePath}));
      })
    }
  }

}
