<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
    <div class="row">
        <div class="col-lg-4">
          <div class="card card-chart">
            <div class="card-header">
              <h4 class="card-title">Total Sales</h4>
              <div ngbDropdown>
                <button type="button" class="btn btn-round btn-default dropdown-toggle btn-simple btn-icon no-caret" ngbDropdownToggle>
                  <fa-icon [icon]="faCog"></fa-icon>
                </button>
                <div ngbDropdownMenu class="dropdown-menu-right">
                  <a class="dropdown-item" role="button" (click)="ytdSelect()">YTD</a>
                  <a class="dropdown-item" role="button" (click)="mtdSelect()">MTD</a>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="chart-area">
                <canvas baseChart id="salesChart"
                            [data]="salesData"
                            [options]="salesChartOptions"
                            [type]="salesChartType"
                            (chartHover)="chartHovered($event)"
                            (chartClick)="chartClicked($event)"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="card card-chart">
              <div class="card-header">
                <h4 class="card-title">Top Selling Products</h4>
                <div ngbDropdown>
                    <button type="button" class="btn btn-round btn-default dropdown-toggle btn-simple btn-icon no-caret" ngbDropdownToggle>
                      <fa-icon [icon]="faCog"></fa-icon>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-right">
                      <a class="dropdown-item" href="#">30 Days</a>
                      <a class="dropdown-item" href="#">90 Days</a>
                    </div>
                  </div>
              </div>
              <div class="card-body">
                <div class="chart-area">
                  <canvas baseChart id="barChartSimpleGradientsNumbers"
                              [data]="topProductData"
                              [options]="topProductOptions"
                              [type]="topProductType"
                              (chartHover)="chartHovered($event)"
                              (chartClick)="chartClicked($event)"></canvas>
                </div>
              </div>
            </div>
          </div>
        <div class="col-lg-4">
            <div class="card card-chart">
              <div class="card-header">
                <h4 class="card-title">Promotion Performance</h4>
                <div ngbDropdown>
                  <button type="button" class="btn btn-round btn-default dropdown-toggle btn-simple btn-icon no-caret" ngbDropdownToggle>
                    <fa-icon [icon]="faCog"></fa-icon>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu-right">
                    <a class="dropdown-item" href="#">Promo 1</a>
                    <a class="dropdown-item" href="#">Promo 2</a>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="chart-area">
                  <canvas baseChart id="promoChart"
                              [data]="promoData"
                              [options]="promoChartOptions"
                              [type]="promoChartType"
                              (chartHover)="chartHovered($event)"
                              (chartClick)="chartClicked($event)"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card  card-tasks">
              <div class="card-header ">
                <h5 class="card-category">Waiting to be shipped</h5>
                <h4 class="card-title">Orders</h4>
              </div>
              <div class="card-body ">
                <div class="table-full-width table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let order of readyToShip">
                        <td class="text-left">{{order.timestamp | date:'mediumDate'}}</td>
                        <td class="text-left">{{getOrderQty(order.products)}}</td>
                        <td class="text-left">{{order.total?.amount | currency}}</td>
                        <td class="text-left" *ngIf="order.tracking?.number != null">
                          {{order.tracking.number}}
                        </td>
                        <td class="text-left" *ngIf="order.tracking?.number == null">
                          <button class="btn-round" id="add_tracking_{{order.id}}" (click)="addTracking(order)">Add Tracking</button>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>  
            <div class="card-footer ">
                <hr>
                <div class="card-footer">
                    <div class="stats" (click)="updatePendingOrders()">
                      <fa-icon [icon]="faSyncAlt"></fa-icon> Last Updated 
                    </div>
                  </div>
              </div>
            </div>   
          </div>
          <div class="col-md-6">
            <div class="card  card-tasks">
              <div class="card-header ">
                <h5 class="card-category">New Reviews</h5>
                <h4 class="card-title">New Reviews</h4>
              </div>
              <div class="card-body ">
                <div class="table-full-width table-responsive">
                  <table class="table">
                    <tbody>
                      <tr *ngFor="let review of reviews$ | async">
                        <td class="text-left">{{review.heading}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>