import { createAction, props } from "@ngrx/store";
import { Company } from "../model/company.model";
import { Image } from "../model/product.model";

export const loadCompany = createAction('[Company] Company Load', props<{id: String}>());
export const loadCompanySuccess = createAction('[Company] Company Load Success', props<{company: Company}>());
export const loadCompanyFailure = createAction('[Company] Company Load Failure');

export const loadCompanies = createAction('[Company] Companies Load');
export const loadCompaniesSuccess = createAction('[Company] Companies Load Success', props<{companies: Company[]}>());
export const loadCompaniesFailure = createAction('[Company] Companies Load Failure');

export const setCurrentCompany = createAction('[Company] Current Company Set', props<{company: Company}>());
export const setCurrentCompanySuccess = createAction('[Company] Current Company Set Success', props<{company: Company}>());
export const setCurrentCompanyFailure = createAction('[Company] Current Company Set Failure');

export const updateCompany = createAction('[Company] Company Update', props<{company: Company}>());
export const updateCompanySuccess = createAction('[Company] Company Update Success', props<{company: Company}>());
export const updateCompanyFailure = createAction('[Company] Company Update Failure');

export const updateCompanyLogo = createAction('[Company] Company Logo Update', props<{company: Company, image: File}>());
export const updateCompanyLogoSuccess = createAction('[Company] Company Logo Update Success', props<{company: Company, image: Image}>());
export const updateCompanyLogoFailure = createAction('[Company] Company Logo Update Failure');
