import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TemplateService } from "../service/template.service";
import * as TemplateActions from "../actions/template.actions";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { of } from "rxjs";
import { CompanyTemplate } from "../model/template.model";
import { Router } from "@angular/router";
import { WebsocketService } from "../service/websocket.service";
import { UserService } from "../service/user.service";

@Injectable()
export class TemplateEffects {
    
    constructor(private actions$: Actions, private templateService: TemplateService, private router: Router, private websocketService: WebsocketService, private userService: UserService){ }

    loadTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TemplateActions.loadTemplates),
            switchMap(() => {
                return this.templateService.getTemplates().pipe(
                    map(templates =>
                        TemplateActions.loadTemplatesSuccess({ templates })
                    ),
                    catchError(() =>
                        of(TemplateActions.loadTemplatesFailure())
                    ));
                }
            )
        )
    )

    updateTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TemplateActions.updateTemplate),
            switchMap((payload: { template: CompanyTemplate }) => {
                return this.templateService.updateCompanyTemplate(payload.template).pipe(
                    map(template => TemplateActions.updateTemplateSuccess({ template })
                    ),
                    catchError(() => of(TemplateActions.updateTemplateFailure())
                ));
            })
        )
    )

    applyCompanyTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TemplateActions.applyTemplateToCompany),
            switchMap((payload: { template: CompanyTemplate }) => {
                return this.templateService.applyTemplate(payload.template).pipe(
                    map(template => TemplateActions.applyTemplateToCompanySuccess({ template })
                    ),
                    catchError(() => of(TemplateActions.applyTemplateToCompanyFailure())
                ));
            })
        )
    )


    updateTemplateImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TemplateActions.updateTemplateImage),
            switchMap((payload: { companyId: String, templateId: String, section: String, imagePath: string, file: File }) => {
                return this.templateService.updateTemplateImage(payload.companyId, payload.templateId, payload.section, payload.imagePath, payload.file).pipe(
                    map(template => TemplateActions.updateTemplateImageSuccess({ template })
                    ),
                    catchError(() => of(TemplateActions.updateTemplateImageFailure())
                ));
            })
        )
    )

    applyTemplateSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TemplateActions.applyTemplateToCompanySuccess),
            tap((payload: { template: CompanyTemplate }) => {
                this.router.navigate(['/application/template/', payload.template.id]);
            })
        ), { dispatch: false }
    )

    updateTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
        ofType(TemplateActions.updateTemplateSuccess, TemplateActions.updateTemplateImageSuccess),
        tap((payload: { template: CompanyTemplate }) => {
            this.userService.getMyCompanyUser(payload.template.companyId).subscribe(profile => {
                this.websocketService.publish({destination: '/topic/' + payload.template.companyId + '/' + profile.id, body: payload.template.id});
            });
        })
    ), { dispatch: false }
)


    getCompanyTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TemplateActions.getCompanyTemplates),
            switchMap((payload: { companyId: String }) => {
            return this.templateService.getCompanyTemplates(payload.companyId).pipe(
                map(templates => TemplateActions.getCompanyTemplatesSuccess({ templates })
                ),
                catchError(() => of(TemplateActions.getCompanyTemplatesFailure())
            ));
        })
    )
)
}