import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Order, Sales, Tracking } from '../model/order.model';
import { SearchResult } from '../model/product.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private httpClient: HttpClient) { }

  getYTDSales(companyId: String): Observable<Sales[]> {
    let params = new HttpParams()
        .set('companyId', String(companyId));
    return this.httpClient.get<Sales[]>(environment.orderUrl + '/sales/YTD', {params});
  }

  getMTDSales(companyId: String, month: String): Observable<Sales[]> {
    let params = new HttpParams()
        .set('companyId', String(companyId));
    return this.httpClient.get<Sales[]>(environment.orderUrl + '/sales/monthly/' + month, {params});

  }

  getTopProductsForPeriod(companyId: String, daysBack: number) {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.httpClient.get<Sales[]>(environment.orderUrl + '/sales/top-products/' + daysBack, {params});
  }

  getOrders(companyId: String, query: String, page: number = 0, size: number) : Observable<SearchResult>{
    let params = new HttpParams()
      .set('companyId', String(companyId))
      .set('q', String(query))
      .set('page', String(page))
      .set('size', String(size));
  
    return this.httpClient.get<SearchResult>(environment.orderUrl + '/order', {params});
  }

  addTracking(companyId: String, orderId: String, tracking: Tracking) : Observable<Order> {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.httpClient.post<Order>(environment.orderUrl + '/order/' + orderId + '/shipped', tracking, {params});
  }

  
}
