export class User {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    shippingAddress: Address;
    billingAddress: Address;
}

export class Address {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
}

export class CompanyUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    roles: Role[];
}

enum Role {
    Admin = 'Admin',
    Company_Manager = "Company Manager",
    Product_Manager = 'Product Manager',
    Promotion_Manager = 'Promotion Manager',
    Service_Manager = 'Service Manager',
    Site_Designer = 'Site Designer',
    Read_Only = 'View'

}