import { createAction, props } from "@ngrx/store";
import { Image, SearchResult } from "../model/product.model";
import { CompanyTemplate, Template } from "../model/template.model";

export const loadTemplates = createAction('[Template] Template Load');
export const loadTemplatesSuccess = createAction('[Template] Template Load Success', props<{templates: Template[]}>());
export const loadTemplatesFailure = createAction('[Template] Template Load Failure');

export const applyTemplateToCompany = createAction('[Template] Apply Template to Company', props<{template: CompanyTemplate}>());
export const applyTemplateToCompanySuccess = createAction('[Template] Apply Template to Company Success', props<{template: CompanyTemplate}>());
export const applyTemplateToCompanyFailure = createAction('[Template] Apply Template to Company Failure');

export const updateTemplate = createAction('[Template] Template Update', props<{template: CompanyTemplate}>());
export const updateTemplateSuccess = createAction('[Template] Template Update Success', props<{template: CompanyTemplate}>());
export const updateTemplateFailure = createAction('[Template] Template Update Failure');

export const getCompanyTemplates = createAction('[Template] Get Company Template', props<{companyId: String}>());
export const getCompanyTemplatesSuccess = createAction('[Template] Get Company Template Success', props<{templates: SearchResult}>());
export const getCompanyTemplatesFailure = createAction('[Template] Get Company Template Failure');

export const updateTemplateImage = createAction('[Template] Update Template Image', props<{companyId: String, templateId: String, section: String, imagePath: string, file: File}>());
export const updateTemplateImageSuccess = createAction('[Template] Update Template Image Success', props<{template: CompanyTemplate}>());
export const updateTemplateImageFailure = createAction('[Template] Update Template Image Failure');