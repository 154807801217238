import { BrowserModule } from '@angular/platform-browser';
import { ApplicationRef, APP_INITIALIZER, DoBootstrap, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TenantInterceptor } from './tenant.interceptor';
import { environment } from 'src/environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { HeaderComponent } from './component/header/header.component';
import { SidenavComponent } from './component/sidenav/sidenav.component';
import { FooterComponent } from './component/footer/footer.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { PromotionsComponent } from './component/promotions/promotions.component';
import { ProductsComponent } from './component/products/products.component';
import { CompanyComponent } from './component/company/company.component';
import { UserProfileComponent } from './component/user-profile/user-profile.component';
import { ProductCreateEditComponent } from './component/product-create-edit/product-create-edit.component';
import { TagInputModule } from 'ngx-chips';
import { NgxColorsModule } from 'ngx-colors';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TreeviewModule } from 'ngx-treeview';
import { EffectsModule } from '@ngrx/effects';
import { CompanyEffects } from './effects/company.effects';
import { ProductsEffects } from './effects/products.effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { CategoryEffects } from './effects/category.effects';
import { OptionGroupComponent } from './component/option-group/option-group.component';
import { FileDndComponent } from './component/file-dnd/file-dnd.component';
import { PromotionCreateEditComponent } from './component/promotion-create-edit/promotion-create-edit.component';
import { DesignerComponent } from './component/designer/designer.component';
import { DraggableDirective } from './util/draggable.directive';
import { DroppableDirective } from './util/droppable.directive';
import { ProductSearchComponent } from './component/product-search/product-search.component';
import { PromotionsEffects } from './effects/promotions.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
import { UserEffects } from './effects/user.effects';
import { TemplateComponent } from './component/application/template/template.component';
import { AssetsComponent } from './component/application/assets/assets.component';
import { LaunchComponent } from './component/application/launch/launch.component';
import { UserProfileCreateComponent } from './component/user-profile-create/user-profile-create.component';
import { CategoriesComponent } from './component/categories/categories.component';
import { localStorageSync } from 'ngrx-store-localstorage';
import { DndDirective } from './component/file-dnd/dnd.directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OrderEffects } from './effects/order.effects';
import { AddEditTrackingComponent } from './component/dashboard/add-edit-tracking/add-edit-tracking.component';
import { OrdersComponent } from './component/orders/orders.component';
import { CustomersComponent } from './component/customers/customers.component';
import { OrderViewComponent } from './component/orders/order-view/order-view.component';
import { CustomerViewComponent } from './component/customers/customer-view/customer-view.component';
import { CustomerEffects } from './effects/customer.effects';
import { TemplateEffects } from './effects/template.effects';
import { TemplateBuilderComponent } from './component/application/template-builder/template-builder.component';
import { WebsocketService } from './service/websocket.service';
import { webSocketServiceFactory } from './service/websocket-service-factory';
import { IconPickerModule } from 'ngx-icon-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AuthConfig, OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { baseUrl } from 'src/environments/environment';
import { ToastrModule } from 'ngx-toastr';

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.authUrl,
  redirectUri: window.location.origin + '/',
  clientId: environment.clientId,
  revocationEndpoint: environment.authUrl + '/oauth2/revoke',
  logoutUrl: environment.authUrl + '/logout',
  responseType: 'code',
  scope: 'openid',
  showDebugInformation: true,
  requireHttps: false,
  disableAtHashCheck: true
};

function initializeKeycloak(authService: OAuthService) {
  return () => {
    authService.configure(authCodeFlowConfig);
    authService.tokenValidationHandler = new JwksValidationHandler();
  }
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['company', 'products', 'orders', 'promotions', 'customers', 'templates'], rehydrate: true})(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,
    FooterComponent,
    DashboardComponent,
    PromotionsComponent,
    ProductsComponent,
    CompanyComponent,
    UserProfileComponent,
    ProductCreateEditComponent,
    OptionGroupComponent,
    FileDndComponent,
    DndDirective,
    PromotionCreateEditComponent,
    DesignerComponent,
    DraggableDirective,
    DroppableDirective,
    ProductSearchComponent,
    TemplateComponent,
    AssetsComponent,
    LaunchComponent,
    UserProfileCreateComponent,
    CategoriesComponent,
    AddEditTrackingComponent,
    OrdersComponent,
    CustomersComponent,
    OrderViewComponent,
    CustomerViewComponent,
    TemplateBuilderComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NgChartsModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [baseUrl],
        sendAccessToken: true
      }
    }),
    FontAwesomeModule,
    NgbModule,
    TagInputModule,
    NgxColorsModule,
    AngularEditorModule,
    BrowserAnimationsModule,
    IconPickerModule,
    TreeviewModule.forRoot(),
    EffectsModule.forRoot([CompanyEffects, ProductsEffects, CategoryEffects, PromotionsEffects, UserEffects, OrderEffects, CustomerEffects, TemplateEffects]),
    StoreModule.forRoot(reducers, {metaReducers}),
    NgxStripeModule.forRoot(environment.stripeKey),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    NgxHotjarModule.forRoot(environment.hotjar),
    NgxHotjarRouterModule,
    NgMultiSelectDropDownModule,
    DragDropModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [OAuthService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    {
      provide: WebsocketService,
      useFactory: webSocketServiceFactory,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

