<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
    <div class="table-responsive" *ngIf="categories$ | async as categories">
        <table class="table">
            <thead class="text-primary">
                <tr>
                  <th scope="col"><input class="form-check" type="checkbox" (change)="selectAll()" [checked]="selectedAll"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let category of categories">
                    <td><input class="form-check" type="checkbox" value="{{category.name}}" [(ngModel)]="category.selected" (change)="checkIfAllSelected()"></td>
                    <td (click)="editCategory(category)">
                      <ngb-highlight [result]="category.name"></ngb-highlight>
                    </td>
                    <td><ngb-highlight [result]="category.description"></ngb-highlight></td>
                  </tr>
        </table>
        <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="categories.length" [(page)]="page" [pageSize]="pageSize" (pageChange)="search()">
            </ngb-pagination>
          
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="search()">
              <option [ngValue]="5">5 items per page</option>
              <option [ngValue]="10">10 items per page</option>
              <option [ngValue]="25">25 items per page</option>
            </select>
          </div>
    </div>
</div>