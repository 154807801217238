import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { faBoxOpen, faBuilding, faChartLine, faMobileAlt, faTag, faTruckLoading, faUserCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { AppAuthGuard } from './app-auth-guard.service';
import { AssetsComponent } from './component/application/assets/assets.component';
import { LaunchComponent } from './component/application/launch/launch.component';
import { TemplateBuilderComponent } from './component/application/template-builder/template-builder.component';
import { TemplateComponent } from './component/application/template/template.component';
import { CompanyComponent } from './component/company/company.component';
import { CustomerViewComponent } from './component/customers/customer-view/customer-view.component';
import { CustomersComponent } from './component/customers/customers.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { OrderViewComponent } from './component/orders/order-view/order-view.component';
import { OrdersComponent } from './component/orders/orders.component';
import { ProductCreateEditComponent } from './component/product-create-edit/product-create-edit.component';
import { ProductsComponent } from './component/products/products.component';
import { PromotionCreateEditComponent } from './component/promotion-create-edit/promotion-create-edit.component';
import { PromotionsComponent } from './component/promotions/promotions.component';
import { UserProfileComponent } from './component/user-profile/user-profile.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AppAuthGuard],
    data: { roles: ['ADMIN'], title: 'Dashboard', icon: faChartLine, menuItem: true },
  },
  {
    path: 'company/:id',
    component: CompanyComponent,
    canActivate: [AppAuthGuard],
    data: { roles: ['ADMIN'], title: 'Company', icon: faBuilding, menuItem: true },
  },
  {
    path: 'products',
    canActivate: [AppAuthGuard],
    data: { roles: ['ADMIN'], title: 'Products', icon: faBoxOpen, menuItem: true },
    children: [
      {
        path: '',
        component: ProductsComponent,
        data: { title: 'List', menuItem: false }
      },
      {
        path: 'new',
        component: ProductCreateEditComponent,
        data: { title: 'Create', menuItem: false }
      },
      {
        path: ':id',
        component: ProductCreateEditComponent,
        data: { title: 'Edit', menuItem: false }
      }
    ]
  },
  {
    path: 'promotions',
    canActivate: [AppAuthGuard],
    data: { roles: ['ADMIN'], title: 'Promotions', icon: faTag, menuItem: true },
    children: [
      {
        path: '',
        component: PromotionsComponent,
        data: { title: 'List', menuItem: false }
      },
      {
        path: 'new',
        component: PromotionCreateEditComponent,
        data: { title: 'Create', menuItem: false }
      },
      {
        path: ':id',
        component: PromotionCreateEditComponent,
        data: { title: 'Edit', menuItem: false }
      }
    ]
  },
  {
    path: 'orders',
    canActivate: [AppAuthGuard],
    data: { roles: ['ADMIN'], title: 'Orders', icon: faTruckLoading, menuItem: true },
    children: [
      {
        path: '',
        component: OrdersComponent,
        data: { title: 'List', menuItem: false }
      },
      {
        path: ':id',
        component: OrderViewComponent,
        data: { title: 'View', menuItem: false }
      }
    ]
  },
  {
    path: 'customers',
    canActivate: [AppAuthGuard],
    data: { roles: ['ADMIN'], title: 'Customers', icon: faUsers, menuItem: true },
    children: [
      {
        path: '',
        component: CustomersComponent,
        data: { title: 'List', menuItem: false }
      },
      {
        path: ':id',
        component: CustomerViewComponent,
        data: { title: 'View', menuItem: false }
      }
    ]
  },
  {
    path: 'profile/:id',
    component: UserProfileComponent,
    canActivate: [AppAuthGuard],
    data: { roles: ['ADMIN'], title: 'Profile', icon: faUserCircle, menuItem: true },
  },
  {
    path: 'application',
    canActivate: [AppAuthGuard],
    data: { roles: ['ADMIN'], title: 'Application', icon: faMobileAlt, menuItem: true },
    children: [
      {
        path: '',
        component: TemplateComponent,
        data: { title: 'Pick Template', menuItem: false }
      },
      {
        path: 'template/:id',
        component: TemplateBuilderComponent,
        data: { title: 'Design Template', menuItem: false }
      },
      {
        path: 'assests',
        component: AssetsComponent,
        data: { title: 'Upload Assets', menuItem: false }
      },
      {
        path: 'launch',
        component: LaunchComponent,
        data: { title: 'Launch', menuItem: false }
      }
    ]
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    data: { roles: ['ADMIN'] },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
