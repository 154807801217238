import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CategoryNode, Category } from '../model/category.model';
import { SearchResult } from '../model/product.model';


@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  getCategoryTree(companyId: String): Observable<CategoryNode[]> {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.http.get<CategoryNode[]>(environment.categoryUrl + '/tree', { params } );
  }


  getDepartments(companyId: String): Observable<Category[]> {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.http.get<Category[]>(environment.categoryUrl + '/root', { params } );
  }

  getCategoriesForParent(parentId: String, companyId: String): Observable<Category[]> {
    let params = new HttpParams().set('companyId', String(companyId));
    return this.http.get<Category[]>(environment.categoryUrl + '/parent/' + parentId, { params } );
  }

  search(query: String,
    page: number = 0,
    size: number,
    sortColumn: String = 'id',
    sort: String = 'asc',
    companyId: String
  ): Observable<SearchResult> {
    let params;
    let sortQuery = sortColumn + ',' + sort;
    if (query != undefined && query != '') {
      params = new HttpParams()
        .set('q', String(query))
        .set('page', String(page))
        .set('size', String(size))
        .set('sort', String(sortQuery))
        .set('companyId', String(companyId));
    } else {
      params = new HttpParams()
        .set('page', String(page))
        .set('size', String(size))
        .set('sort', String(sortQuery))
        .set('companyId', String(companyId));
    }
    return this.http.get<SearchResult>(
      environment.categoryUrl + '/search',
      { params }
    );
  }
}
