<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
            <div class="card-header">
                <h4 class="card-title">Templates</h4>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-md-4 pr-1">
                    <div ngbDropdown class="d-inline-block">
                      <button type="button" class="btn btn-outline-primary" id="templateDD" ngbDropdownToggle>Edit Template</button>
                      <div ngbDropdownMenu aria-labelledby="templateDD">
                        <button ngbDropdownItem *ngFor="let t of companyTemplates$ | async" (click)="pickCompanyTemplate(t.id)">{{t.name}}</button>
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngFor="let t of templates$ | async" class="col-md-4 text-center">
                        <div class="col-md-4">
                            <span class="title">{{t.name}}</span>
                            <ngb-carousel *ngIf="t.images" [showNavigationArrows]="true" [showNavigationIndicators]="false" [interval]="3000">
                              <ng-template ngbSlide *ngFor="let image of t.images">
                                <div class="img-wrapper">
                                  <img class="template-image" [src]="image.filePath">
                                </div>
                              </ng-template>
                            </ngb-carousel>
                            <button type="button" class="btn btn-round" (click)="pickTemplate(t.id)">Try Theme</button>
                         </div>
                    </div>
                </div>
                <div *ngIf="templateSelected" class="row">
                    <form (submit)="applyTemplate()">
                        <div class="row">
                          <div class="col-md-5 pr-1">
                            <div class="form-group">
                              <input type="text" class="form-control" placeholder="name" [(ngModel)]="companyTemplate.name" name="name">
                            </div>
                          </div>
                          <div class="col-md-4 px-1">
                            <div class="form-group">
                              <input type="text" class="form-control datetimepicker" placeholder="Start Date" [(ngModel)]="startDate" name="start-date" ngbDatepicker #ds="ngbDatepicker" (click)="ds.toggle()">
                            </div>
                          </div>
                          <div class="col-md-3 pl-1">
                            <div class="form-group">
                              <input name="end-date" type="text" class="form-control datetimepicker" placeholder="End Date" [(ngModel)]="endDate" ngbDatepicker #de="ngbDatepicker" (click)="de.toggle()">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                                <button type="submit" class="btn btn-round btn-default btn-simple">Apply Template</button>
                            </div>
                          </div>
                        </div>
                    </form>
                </div>  
            </div>
        </div>
    </div>
</div>