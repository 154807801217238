import { Action, createReducer, on } from "@ngrx/store";
import { CompanyUser } from "../model/user.model";
import * as UserActions from "../actions/user.actions";

export interface UserState {
    users: CompanyUser[];
    currentUser: String;
}

export const initialUserState = {
    users: new Array<CompanyUser>(),
    currentUser: new String()
}

const reducer = createReducer(
    initialUserState,
    on(UserActions.loadUserSuccess,
        (state, { user }) => ({
            ...state, currentUser: user?.id, users: mergeUsers(state.users, [user])
        })
    ),
    on(UserActions.loadMyUserSuccess,
        (state, { user }) => ({
            ...state, currentUser: user?.id, users: mergeUsers(state.users, [user])
        })
    ),
    on(UserActions.updateCompanyUserSuccess,
        (state, { user }) => ({
            ...state, currentUser: user?.id, users: mergeUsers(state.users, [user])
        }))
)

export function userReducer(state: UserState | undefined, action: Action) {
    return reducer(state, action);
}

function mergeUsers(state: CompanyUser[], users: CompanyUser[]){
    let newUsers = [...state, ...users]
    const uniqueArray = newUsers.reduce((newArray: CompanyUser[], item) =>{
        if (newArray.find(i => i.id === item.id)){
            return newArray
        } else {
            return [...newArray, item]
        }
    }, [])
    return uniqueArray;
}