import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CategoryService } from "../service/category.service";
import * as CategoryActions from '../actions/category.actions';
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";

@Injectable()
export class CategoryEffects {
    
    constructor(private actions$: Actions, private categoryService: CategoryService){ }

    loadCategoryTree$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.loadCategoryTree),
            switchMap((payload: { companyId: string}) => {
                return this.categoryService.getCategoryTree(payload.companyId).pipe(
                    map(categories =>
                        CategoryActions.loadCategoryTreeSuccess({ categories })
                    ),
                    catchError(() =>
                        of(CategoryActions.loadCategoryTreeFailure())
                    ));
                    }
            )
        )
    )

    loadCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.loadCategories),
            switchMap((payload: { companyId: string}) => {
                return this.categoryService.search('',0, 100000, 'id', 'asc', payload.companyId).pipe(
                    map(categories =>
                        CategoryActions.loadCategoriesSuccess({ categories: categories.content })
                    ),
                    catchError(() =>
                        of(CategoryActions.loadCategoriesFailure())
                    ));
                    }
            )
        )
    )

}