import { ActionReducerMap, createSelector, USER_PROVIDED_META_REDUCERS } from "@ngrx/store";
import { ProductsState, productReducer } from "./products.reducer";
import { CompanyState, companyReducer } from "./company.reducer";
import { CategoryState, categoryReducer } from "./category.reducer";
import { Product } from "../model/product.model";
import { promotionReducer, PromotionsState } from "./promotions.reducer";
import { Promotion } from "../model/promotion.model";
import { Company } from "../model/company.model";
import { userReducer, UserState } from "./user.reducer";
import { orderReducer, OrderState } from "./order.reducer";
import { Order } from "../model/order.model";
import { customerReducer, CustomerState } from "./customer.reducer";
import { User } from "../model/user.model";
import { templateReducer, TemplateState } from "./template.reducer";
import { CompanyTemplate, Template } from "../model/template.model";


export interface AppState {
    products: ProductsState;
    company: CompanyState;
    category: CategoryState
    promotions: PromotionsState;
    users: UserState;
    orders: OrderState;
    customers: CustomerState;
    templates: TemplateState;
}

export const reducers: ActionReducerMap<AppState> = {
    products: productReducer,
    company: companyReducer,
    category: categoryReducer,
    promotions: promotionReducer,
    users: userReducer,
    orders: orderReducer,
    customers: customerReducer,
    templates: templateReducer
}

export const selectProducts = (state: AppState) => state.products;
export const selectSearchResultProducts = createSelector(selectProducts, (products: ProductsState) => products.products)
export const selectProduct = createSelector(selectProducts, (products: ProductsState, id: String) => products.products.find((p: Product) => p.id === id));

export const selectCompanies = (state: AppState) => state.company.companies;
export const selectCompany = createSelector(selectCompanies, (companies: Company[], id: String) => companies.find((c: Company) => c.id === id));
export const selectCurrentCompany = (state: AppState) => state.company.currentCompany;

export const selectCategoryTree = (state: AppState) => state.category.categoryTree;
export const selectCategories = (state: AppState) => state.category.categories;

export const selectPromotions = (state: AppState) => state.promotions;
export const selectSearchResultPromos = createSelector(selectPromotions, (promotions: PromotionsState) => promotions.promotions)
export const selectPromo = createSelector(selectPromotions, (promotions: PromotionsState, id: String) => promotions.promotions.find((p: Promotion) => p.id === id));

export const selectCompanyUsers = (state: AppState) => state.users;
export const selectUser = createSelector(selectCompanyUsers, (users: UserState, id: String) => users.users.find(u => u.id === id));
export const selectCurrentUser = createSelector(selectCompanyUsers, (users: UserState) => users.users.find(u => u.id === users.currentUser));

export const selectOrders = (state: AppState) => state.orders.orders;
export const selectOrderCount = (state: AppState) => state.orders.totalOrders;
export const selectYTDSales = (state: AppState) => state.orders.ytdSales;
export const selectMonthSales = (state: AppState) => state.orders.mtdSales;
export const selectTopProducts = (state: AppState) => state.orders.topProducts;
export const selectOrder = createSelector(selectOrders, (orders: Order[], id: String) => orders.find((o: Order) => o.id === id));

export const selectCustomers = (state: AppState) => state.customers.customers;
export const selectCustomerCount = (state: AppState) => state.customers.totalCustomers;
export const selectCustomer = createSelector(selectCustomers, (customers: User[], id: String) => customers.find((u: User) => u.id === id));

export const selectTemplates = (state: AppState) => state.templates.templates;
export const selectTemplate = createSelector(selectTemplates, (templates: Template[], id: String) => templates.find((t: Template) => t.id === id));
export const selectCompanyTemplates = (state: AppState) => state.templates.companyTemplates;
export const selectCompanyTemplate = createSelector(selectCompanyTemplates, (templates: CompanyTemplate[], id: String) => templates.find((t: CompanyTemplate) => t.id === id));