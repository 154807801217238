import { Component, OnInit } from '@angular/core';
import { faBell, faChartLine, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { AppState, selectCurrentCompany } from './reducers';
import * as CompanyActions from 'src/app/actions/company.actions';
import * as CategoryActions from 'src/app/actions/category.actions';
import { searchProducts } from './actions/products.actions';
import { loadPromos } from './actions/promotions.actions';
import { Router } from '@angular/router';
import { loadUser, loadCompanyUsers, loadMyUser } from './actions/user.actions';
import { Company } from './model/company.model';
import * as TemplateActions from './actions/template.actions';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'admin-portal';

  faChartLine = faChartLine;
  faBell = faBell;
  faUserCircle = faUserCircle;
  selectedCompany: Company;
  userId: String;

  constructor(private store: Store<AppState>, private router: Router,){}

  ngOnInit(): void {
    this.store.dispatch(CompanyActions.loadCompanies());
    this.store.select(selectCurrentCompany).subscribe(c => {
      this.selectedCompany = c
      this.store.dispatch(loadMyUser({companyId: this.selectedCompany.id}));
      this.store.dispatch(TemplateActions.loadTemplates());
      this.store.dispatch(TemplateActions.getCompanyTemplates({companyId: this.selectedCompany.id}));
    });
    
  }

  companySelected(event: any) {
    this.selectedCompany = event;
    this.store.dispatch(CompanyActions.setCurrentCompany({company: this.selectedCompany}))
    this.store.dispatch(CategoryActions.loadCategoryTree({companyId: this.selectedCompany.id}));
    this.store.dispatch(searchProducts({query: '', page: 0, size: 10, companyId: this.selectedCompany.id}));
    this.store.dispatch(loadPromos({query: '', page: 0, size: 10, companyId: this.selectedCompany.id}));
    this.store.dispatch(loadCompanyUsers({companyId: this.selectedCompany.id}));
    this.router.navigate(['']);

  }
}

