
import { Image, MonetaryAmount } from "./product.model";
import { Address } from "./user.model";

export class Order {
    id!: string;
    number!: string;
    userId: string;
    userEmail: string;
    cartId!: string;
    timestamp: Date;
    products: OrderProduct[];
    total!: MonetaryAmount | null;
    tax!: MonetaryAmount | null;
    shipping!: MonetaryAmount | null;
    savings!: MonetaryAmount | null;
    shipToAddress!: Address;
    billToAddress!: Address;
    tracking!: Tracking;
    status: string;
    
}

export class OrderProduct {
    id!: string;
    variantId: string;
    image: string;
    name: string;
    upc: string;
    weight: number;
    width: number;
    height: number;
    depth: number;
    description: string;
    quantity: number;
}

export class Tracking {
    vendor: string;
    number: string;
    estimatedDelivery: Date;
}

export class Sales {
    label: string;
    amount: number;
}