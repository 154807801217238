import { createAction, props } from "@ngrx/store";
import { Product, SearchResult } from "../model/product.model";

export const loadFeaturedProducts = createAction('[Products] Featured Products Load');
export const loadFeaturedProductsSuccess = createAction('[Products] Featured Products Load Success', props<{products: Product[]}>());
export const loadFeaturedProductsFailure = createAction('[Products] Featured Products Load Failure');

export const loadProducts = createAction('[Products] Load Products', props<{page: number, companyId: string}>());
export const loadProductsSuccess = createAction('[Products] Load Products Success', props<{products: Product[]}>());
export const loadProductsFailure = createAction('[Products] Load Products Failure');

export const searchProducts = createAction('[Products] Search Products', props<{query: string, page: number, size: number, companyId: string}>());
export const searchProductsSuccess = createAction('[Products] Search Products Success', props<{products: SearchResult}>());
export const searchProductsFailure = createAction('[Products] Search Products Failure');

export const loadProduct = createAction('[Products] Load Product', props<{productId: string, companyId: string}>());
export const loadProductSuccess = createAction('[Products] Load Product Success', props<{product: Product}>());
export const loadProductFailure = createAction('[Products] Load Product Failure');

export const updateProduct = createAction('[Products] Update Product', props<{product: Product, files: File[], companyId: string}>());
export const updateProductSuccess = createAction('[Products] Update Product Success', props<{product: Product}>());
export const updateProductFailure = createAction('[Products] Update Product Failure');

export const createProduct = createAction('[Products] Create Product', props<{product: Product, files: File[], companyId: string}>());
export const createProductSuccess = createAction('[Products] Create Product Success', props<{product: Product}>());
export const createProductFailure = createAction('[Products] Create Product Failure');