<div *ngIf="company" class="logo">
    <a href="https://{{company.domainName}}" class="simple-text logo-mini">
      <div class="logo-img">
          <img src="{{company.logo.filePath}}"/>
      </div>
    </a>
   
    <a href="https://{{company.domainName}}" class="simple-text logo-normal">
        {{company.name}}        
    </a>
</div>
<div class="sidebar-wrapper">
    <ul *ngIf="company" class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems">
            <div *ngIf="showChildren(menuItem); else noSubMenu" >
                <div (click)="clickedMenu($event, menuItem)" class="nav-item" id="{{menuItem.data.title}}">
                    <fa-icon class="admin-icons" [icon]="menuItem.data.icon"></fa-icon>
                    <p>{{menuItem.data.title}}</p>
                </div>
                <div class="collapse" [ngbCollapse]="parentId !== menuItem.data.title">
                    <div *ngFor="let child of menuItem.children">
                        <div *ngIf="child.data.menuItem" class="sub-nav">
                            <a [routerLink]="[menuItem.path + '/' + child.path]">
                                <p>{{child.data.title}}</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noSubMenu>
                <a [routerLink]="getLink(menuItem)">
                    <fa-icon class="admin-icons" [icon]="menuItem.data.icon"></fa-icon>
                    <p>{{menuItem.data.title}}</p>
                </a>
            </ng-template>
        </li>
    </ul>
</div>