<div class="form-group">
    <input class="form-control" placeholder="Option Name" [(ngModel)]="optionGroup.name" name="name" required />
</div>
 <div class="row">
    <div class="col">
      <div ngbDropdown class="d-inline-block" #drop="ngbDropdown">
        <button type="button" (click)="$event.stopPropagation(); drop.open()" class="btn btn-outline-primary" id="option-type" ngbDropdownToggle>{{getDropdownValue()}}</button>
        <div ngbDropdownMenu aria-labelledby="option-type">
          <button type="button" ngbDropdownItem (click)="optionTypeSelect('SWATCH')">Color Swatch</button>
          <button type="button" ngbDropdownItem (click)="optionTypeSelect('DROPDOWN')">Dropdown</button>
          <button type="button" ngbDropdownItem (click)="optionTypeSelect('RADIO')">Radio Button</button>
          <button type="button" ngbDropdownItem (click)="optionTypeSelect('LIST')">Rectangle List</button>
        </div>
      </div>
    </div> 
 </div>

<tag-input [separatorKeyCodes]="[13,44]" 
    [(ngModel)]="optionGroup.options"
    [identifyBy]="'id'"
    [displayBy]="'label'"
    (onAdd)="add($event)" 
    (onRemove)="remove($event)"
    [secondaryPlaceholder]="'Options'"
    [inputClass]="'form-control'"
    name="option-value" #input>
<ng-template let-item="item" let-index="index">
    <div *ngIf="optionGroup.type == 'SWATCH'" id="chip_{{index}}" >
        {{ item.label }}
      <fa-icon ngx-colors-trigger [icon]="faCircle" [(ngModel)]="item.value" [style.color]="item.value"></fa-icon>  
      <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
    </div>
    <div *ngIf="optionGroup.type != 'SWATCH'" class="chip" id="chip_{{index}}">
        {{ item.label }}
      <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
    </div>
</ng-template>
</tag-input>
