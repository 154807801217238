import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { searchProducts } from 'src/app/actions/products.actions';
import { Product } from 'src/app/model/product.model';
import { AppState, selectCurrentCompany, selectSearchResultProducts } from 'src/app/reducers';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss']
})
export class ProductSearchComponent implements OnInit {

  @Output() selected = new EventEmitter<Product[]>();

  products$: Observable<Product[]>;
  products = new Array<Product>()
  pageSize: number = 10;
  companyId: string;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.select(selectCurrentCompany).subscribe(company => this.companyId = company.id);
    this.store.dispatch(searchProducts({query: '', page: 0, size: this.pageSize, companyId: this.companyId}));
    this.products$ = this.store.select(selectSearchResultProducts,0)
    this.products$.subscribe(prods => this.products = JSON.parse(JSON.stringify(prods)));
  }

  search(query: any){
    query = query.target.value.trim() // Remove whitespace
    query = query.toLowerCase() // Datasource defaults to lowercase matches
    this.store.dispatch(searchProducts({query: query, page: 0, size: this.pageSize, companyId: this.companyId}));
  }

  getImageUrl(product: Product) {
    return product.variants[0]?.images[0]?.filePath
  }

  addProducts(){
    this.selected.emit(this.products.filter(prod => prod.selected));
  }

}
