<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"> Orders</h4>
        </div>
        <div class="card-body">
            <div class="table-full-width table-responsive">
                <table class="table">
                  <thead class="text-primary">
                    <tr>
                      <th>Date</th>
                      <th>Products</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Tracking</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let order of orders" (click)="viewOrder(order.id)">
                      <td class="text-left">{{order.timestamp | date:'mediumDate'}}</td>
                      <td class="text-left">{{order.products.length}}</td>
                      <td class="text-left">{{order.total?.amount | currency}}</td>
                      <td class="text-left">{{order.status}}</td>
                      <td class="text-left" *ngIf="order.tracking?.number != null">
                        {{order.tracking.number}}
                      </td>
                    </tr>
                  </tbody>
              </table>
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination [collectionSize]="orderSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="search()">
                </ngb-pagination>
              
                <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="search()">
                  <option [ngValue]="5">5 items per page</option>
                  <option [ngValue]="10">10 items per page</option>
                  <option [ngValue]="25">25 items per page</option>
                </select>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
