import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { Company } from 'src/app/model/company.model';
import { AppState } from 'src/app/reducers';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() company: Company;
  @Input() userId: String;
  menuItems!: any[];
  parentId = "";
  tokenHelper = new JwtHelperService();

  constructor(private route: Router, private store: Store<AppState>, private authService: OAuthService) { }

  ngOnInit(): void {
    this.menuItems = this.route.config.filter(config => config.data?.menuItem && this.checkRoles(config.data?.roles));
  
  }

  private checkRoles(access: String[]) : boolean {
    let decoded = this.tokenHelper.decodeToken(this.authService.getAccessToken()!);
    var roles = decoded.authorities;
    const hasRoles = roles.filter((role: String) => access.includes(role));
    return hasRoles.length > 0;
  }

  getLink(menuItem: any) {
    return menuItem.path == 'company/:id'? menuItem.path.replace(':id', this.company.id):menuItem.path || menuItem.path == 'profile/:id'? menuItem.path.replace(':id', this.userId):menuItem.path
  }

  showChildren(menuItem: any){
    if (menuItem.children == null) {
      return false;
    } 
    return menuItem.children.filter((child: { menuItem: any; }) => child.menuItem).length > 0
  }
  
  clickedMenu(event: { currentTarget: any; }, menuItem: any) {
    var target = event.currentTarget;
    let parentId = target.id;
    if (parentId == this.parentId) {
      this.parentId = "";
    } else {
      this.parentId = target.id;
    }
    this.route.navigate([menuItem.path + '/' + menuItem.children[0].path])
  }

}
