import { createAction, props } from "@ngrx/store";
import { CompanyUser } from "../model/user.model";

export const loadUser = createAction('[User] User Load', props<{id: string}>());
export const loadUserSuccess = createAction('[User] User Load Success', props<{user: CompanyUser}>());
export const loadUserFailure = createAction('[User] User Load Failure');

export const loadMyUser = createAction('[User] My User Load', props<{companyId: string}>());
export const loadMyUserSuccess = createAction('[User] My User Load Success', props<{user: CompanyUser}>());
export const loadMyUserFailure = createAction('[User] My User Load Failure');

export const loadCompanyUsers = createAction('[User] Users Load', props<{companyId: string}>());
export const loadCompanyUsersSuccess = createAction('[User] Users Load Success', props<{users: CompanyUser[]}>());
export const loadCompanyUsersFailure = createAction('[User] Users Load Failure');

export const updateCompanyUser = createAction('[User] User Update', props<{user: CompanyUser, companyId: string}>());
export const updateCompanyUserSuccess = createAction('[User] User Update Success', props<{user: CompanyUser}>());
export const updateCompanyUserFailure = createAction('[User] User Update Failure');