import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Image, SearchResult } from '../model/product.model';
import { CompanyTemplate, Template } from '../model/template.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private httpClient: HttpClient) { }


  getTemplates() : Observable<Template[]>{
    return this.httpClient.get<Template[]>(
      environment.templateUrl + '/templates');
  }

  applyTemplate(template: CompanyTemplate) : Observable<CompanyTemplate> {
    let pipe = new DatePipe('en-US');
    let request = JSON.stringify(template, function(k,v){ 
      if (this[k] instanceof Date) {
        return pipe.transform(this[k], "yyyy-MM-dd'T'HH:mm:ss");
      }
      return v;
    });
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<CompanyTemplate>(environment.companyUrl + '/' + template.companyId + '/template/' + template.templateId, request, {headers: headers});
  }

  updateCompanyTemplate(template: CompanyTemplate) : Observable<CompanyTemplate> {
    return this.httpClient.put<CompanyTemplate>(environment.companyUrl + '/' + template.companyId + '/template/' + template.id, template);
  }

  getCompanyTemplates(companyId: String) : Observable<SearchResult> {
    return this.httpClient.get<SearchResult>(environment.companyUrl + '/app/' + companyId + '/template');
  }

  updateTemplateImage(companyId: String, templateId: String, section: String, imagePath: string, file: File) : Observable<CompanyTemplate> {
    const formData = new FormData();
    formData.append('imagePath', imagePath);
    formData.append('file', file);
    return this.httpClient.put<CompanyTemplate>(environment.companyUrl + '/' + companyId + '/template/' + templateId + '/image/' + section, formData);
  }
}
