import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faBell, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Company } from 'src/app/model/company.model';
import { AppState, selectCompanies, selectCompany, selectCustomer } from 'src/app/reducers';
import * as CompanyActions from 'src/app/actions/company.actions';
import { OAuthService } from 'angular-oauth2-oidc';
import { CompanyUser } from 'src/app/model/user.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() company = new EventEmitter<String>()

  constructor(private route: ActivatedRoute, private authServer: OAuthService, private store: Store<AppState>) { }

  companies$: Observable<Company[]>;
  companies: Company[]
  faUserCircle = faUserCircle;
  faBell = faBell;
  tokenHelper = new JwtHelperService();
  profileId: String;

  ngOnInit(): void {
    this.companies$ = this.store.select(selectCompanies);
    this.companies$.subscribe(c => {
      this.companies = JSON.parse(JSON.stringify(c))
    });
    let decoded = this.tokenHelper.decodeToken(this.authServer.getAccessToken()!);
    this.profileId = decoded.sub;
  }

  selectCompany(value: any) {
    this.store.dispatch(CompanyActions.setCurrentCompany({company: value.target.value}))
    this.company.emit(value.target.value);
  }

  logout(){
    this.authServer.logOut();
  }
 
  getTitle(){
    var data = this.route.routeConfig?.data;
    if (data){
      return data.title;
    } 
  }

}
