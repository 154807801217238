import { createAction, props } from "@ngrx/store";
import { SearchResult } from "../model/product.model";
import { Promotion } from "../model/promotion.model";

export const loadPromos = createAction('[Promotions] Load Promotions', props<{query: string, page: number, size: number, companyId: string}>());
export const loadPromosSuccess = createAction('[Promotions] Load Promotions Success', props<{promotions: SearchResult}>());
export const loadPromosFailure = createAction('[Promotions] Load Promotions Failure');

export const loadPromo = createAction('[Promotions] Load Promotion', props<{promotionId: string, companyId: string}>());
export const loadPromoSuccess = createAction('[Promotions] Load Promotion Success', props<{promotion: Promotion}>());
export const loadPromoFailure = createAction('[Promotions] Load Promotion Failure');

export const updatePromo = createAction('[Promotions] Update Promotion', props<{promotion: Promotion, companyId: string, file: File}>());
export const updatePromoSuccess = createAction('[Promotions] Update Promotion Success', props<{promotion: Promotion}>());
export const updatePromoFailure = createAction('[Promotions] Update Promotion Failure');

export const createPromo = createAction('[Promotions] Create Promotion', props<{promotion: Promotion, companyId: string, file: File}>());
export const createPromoSuccess = createAction('[Promotions] Create Promotion Success', props<{promotion: Promotion}>());
export const createPromoFailure = createAction('[Promotions] Create Promotion Failure');