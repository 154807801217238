import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Company } from '../model/company.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Image } from '../model/product.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http : HttpClient) { }

  getCompany(id: String) : Observable<Company> {
    let params = new HttpParams().set('companyId', String(id));
    return this.http.get<Company>(environment.companyUrl + "/" + id, { params } );
  }

  getCompanies() : Observable<Company[]> {
    //let params = new HttpParams().set('companyId', String(""));
    return this.http.get<Company[]>(environment.companyUrl);
  }

  updateCompany(company: Company) : Observable<Company> {
    let params = new HttpParams().set('companyId', String(company.id));
    return this.http.put<Company>(environment.companyUrl + '/' + company.id, company, { params } );
  }

  updateCompanyLogo(id: String, file: File) : Observable<Image> {
    let params = new HttpParams().set('companyId', String(id));
    const formData = new FormData();
    formData.append('file', file);

    return this.http.put<Image>(environment.companyUrl + '/' + id + '/image', formData, { params } );
  }
}
