import { Brand } from './brand.model';
import { Category } from './category.model';
import { Inventory } from './inventory.model';
import { OptionGroup, Option } from './option-group.model';

export class Product {
  id: string;
  version: number;
  name: string;
  description!: string;
  profileImage!: Image;
  tags: String[] = [];
  weight: number;
  width: number;
  height: number;
  depth: number;
  condition!: string;
  brand: Brand = new Brand;
  categories: Category[] = [];
  optionGroups: OptionGroup[] = [];
  variants: ProductVariant[] = [];
  rating: number;
  active: boolean = false;
  featured: boolean = false;
  taxable: boolean = false;
  taxRate: number;
  selected: boolean = false;
}

export class ProductVariant {
  id: string;
  upc: string;
  sku: string;
  amount: MonetaryAmount;
  saleAmount!: MonetaryAmount | null;
  cost!: MonetaryAmount | null;
  images: Image[] = [];
  attributes!: Map<string, string>;
  inventory: Inventory[] = [];
  options: Option[] = [];
  showSelect: boolean = false;
  optionSelected: boolean = false;
}

export class SearchResult {
  content: any[] = [];
  number: number;
  totalPages: number;
  totalElements: number;
}

export class Image {
  fileName: string;
  filePath: string;
  key: string;
  etag: string;
  order: number;
}

export class MonetaryAmount {
  amount: number;
  currency: string;
  
}
