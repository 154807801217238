import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchResult } from '../model/product.model';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private httpClient: HttpClient) { }

  searchCustomers(
    query: String,
    page: number = 0,
    size: number,
    sortColumn: String,
    sort: String,
    companyId: String
  ): Observable<SearchResult> {
    let params;
    let sortQuery = sortColumn + ',' + sort;
    if (query != undefined && query != '') {
      params = new HttpParams()
        .set('q', String(query))
        .set('page', String(page))
        .set('size', String(size))
        .set('sort', String(sortQuery))
        .set('companyId', String(companyId));
    } else {
      params = new HttpParams()
        .set('page', String(page))
        .set('size', String(size))
        .set('sort', String(sortQuery))
        .set('companyId', String(companyId));
    }
    return this.httpClient.get<SearchResult>(
      environment.userUrl + '/profile/search',
      { params }
    );
  }

  getCustomer(
    id: string,
    companyId: String
  ): Observable<User> {
    let params = new HttpParams()
    .set('companyId', String(companyId));
    return this.httpClient.get<User>(
      environment.userUrl + '/profile/' + id,
      { params }
    );
  }

}
