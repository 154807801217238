import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircle, faFillDrip } from '@fortawesome/free-solid-svg-icons';
import { Option, OptionGroup } from 'src/app/model/option-group.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-option-group',
  templateUrl: './option-group.component.html',
  styleUrls: ['./option-group.component.scss'],
})
export class OptionGroupComponent implements OnInit {
  constructor() {}


  @Input() optionGroup: OptionGroup;
  @Output() addEvent = new EventEmitter<Option>();
  @Output() removeEvent = new EventEmitter<Option>();

  faFillDrip = faFillDrip;
  faCircle = faCircle;

  ngOnInit(): void {}

  remove(tag: any): void {
    let option = new Option();
    option.groupId = this.optionGroup.id;
    option.id = tag.id;
    option.label = tag.label;
    this.removeEvent.emit(option);
  }

  add(tag:any): void {
    let option = new Option();
    option.groupId = this.optionGroup.id;
    option.id = uuidv4();
    tag.id = option.id;
    option.label = tag.label;
    this.addEvent.emit(option);
  }

  optionTypeSelect(value: string){
    this.optionGroup.type = value;
  }

  getDropdownValue(){
    let value = 'Selector Type'
    switch(this.optionGroup.type) {
      case 'SWATCH': {
        value = 'Color Swatch';
        break;
      }
      case 'DROPDOWN':{
        value = 'Dropdown';
        break;
      }
      case 'RADIO':{
        value = 'Radio Button';
        break;
      }
      case 'LIST':{
        value = 'Rectangle List';
        break;
      } 
    }
    return value;
  }

}
