import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn:'root'
})
export class AppAuthGuard implements CanActivate {
  constructor(protected router: Router, protected authService: OAuthService) {
  }

  tokenHelper = new JwtHelperService();
  roles: String[];

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise(async (resolve, reject) => {
      if (!this.authService.hasValidAccessToken()) {
        this.authService.loadDiscoveryDocument(environment.discoveryUrl)
          .then(() => this.authService.tryLogin()
            .then(() => this.authService.initLoginFlow()));
        this.authService.setupAutomaticSilentRefresh();
        return; 
      }
      let decoded = this.tokenHelper.decodeToken(this.authService.getAccessToken()!);
      this.roles = decoded.authorities;
      const requiredRoles = route.data.roles;
      let granted: boolean = false;
      if (!requiredRoles || requiredRoles.length === 0) {
        granted = true;
      } else {
        for (const requiredRole of requiredRoles) {
          if (this.roles.indexOf(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
      }

      if(granted === false) {
        this.router.navigate(['/']);
      }
      resolve(granted);

    });
  }

}
