import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadCustomers } from 'src/app/actions/customer.actions';
import { User } from 'src/app/model/user.model';
import { AppState, selectCurrentCompany, selectCustomerCount, selectCustomers } from 'src/app/reducers';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  constructor(private router: Router, private store: Store<AppState>) { }

  customers$: Observable<User[]>
  customers = new Array<User>();
  pageSize = 10;
  page = 0;
  companyId: string;
  customerSize = 0;

  ngOnInit(): void {
    if (this.page != 0){
      this.page =- 1;
    }
    this.store.select(selectCurrentCompany).subscribe(company => {
      this.companyId = company.id;
      this.store.dispatch(loadCustomers({query: '', page: this.page, size: this.pageSize, companyId: company.id}))
    })
    this.customers$ = this.store.select(selectCustomers);
    this.store.select(selectCustomerCount).subscribe(size => this.customerSize = size);
    this.customers$.subscribe(c => this.customers = JSON.parse(JSON.stringify(c)));
  }

  viewCustomer(id: string){
    this.router.navigate(['/customers/', id])
  }

  search(){
    this.store.dispatch(loadCustomers({query: '', page: this.page -1, size: this.pageSize, companyId: this.companyId}))
  }

}
