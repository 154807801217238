<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"> Orders</h4>
        </div>
        <div class="card-body" *ngIf="order$ | async">
            <form (submit)="submit()">
              <div class="row">
                <div class="col-md-5 pr-1">
                  <div class="form-group">
                    <label>Order Number</label>
                    <input readonly type="text" class="form-control" [(ngModel)]="order.number" name="number">
                  </div>
                </div>
                <div class="col-md-3 pl-1">
                  <div class="form-group">
                    <label for="status">Status</label>
                    <input readonly name="status" type="text" class="form-control" [(ngModel)]="order.status">
                  </div>
                </div>
                <div class="col-md-4 px-1">
                  <div class="form-group">
                    <label>Customer</label>
                    <a role="button" (click)="viewCustomer(order.userId)">{{order.userEmail}}</a>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 pr-5">
                    <h4>Products</h4>
                    <table class="table">
                        <thead class="text-primary">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Quantity</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let product of order.products">
                                <td>
                                  <img [src]="getImageUrl(product)" class="mr-2" style="width: 50px">
                                  <ngb-highlight [result]="product.name"></ngb-highlight>
                                </td>
                                <td><ngb-highlight [result]="product.quantity.toString()"></ngb-highlight></td>
                              </tr>
                    </table>
                </div>
                <div class="col-md-4 pr-5">
                    <h4>Address</h4>
                    <div class="row">
                        <label>{{order.shipToAddress.line1}}</label>
                    </div>
                    <div class="row">
                        <label>{{order.shipToAddress.line2}}</label>
                    </div>
                    <div class="row">
                        <label>{{order.shipToAddress.city}}, {{order.shipToAddress.state}} {{order.shipToAddress.postalCode}}</label>
                    </div>
                </div>
              </div>
              <div class="col-md-2 pr-1">
                  <button type="submit" class="btn btn-round btn-default btn-simple">Update</button>
              </div>
            </form>
          </div>
      </div>
    </div>
  </div>
</div>
