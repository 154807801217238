import { createAction, props } from "@ngrx/store";
import { SearchResult } from "../model/product.model";
import { User } from "../model/user.model";

export const loadCustomers = createAction('[Customers] Load Customers',props<{query: string, page: number, size: number, companyId: string}>());
export const loadCustomersSuccess = createAction('[Customers] Load Customers Success', props<{customers: SearchResult}>());
export const loadCustomersFailure = createAction('[Customers] Load Customers Failure');

export const loadCustomer = createAction('[Customers] Load Customer',props<{companyId: string, id: string}>());
export const loadCustomerSuccess = createAction('[Customers] Load Customer Success', props<{customer: User}>());
export const loadCustomerFailure = createAction('[Customers] Load Customer Failure');