<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"> Customers</h4>
        </div>
        <div class="card-body">
          <div class="table-full-width table-responsive">
            <table class="table">
              <thead class="text-primary">
                <tr>
                  <th>FirstName</th>
                  <th>LastName</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let customer of customers$ | async" (click)="viewCustomer(customer.id)">
                  <td class="text-left">{{customer.firstName}}</td>
                  <td class="text-left">{{customer.lastName}}</td>
                  <td class="text-left">{{customer.email}}</td>
                </tr>
              </tbody>
          </table>
          <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="customerSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="search()">
            </ngb-pagination>
          
            <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="search()">
              <option [ngValue]="5">5 items per page</option>
              <option [ngValue]="10">10 items per page</option>
              <option [ngValue]="25">25 items per page</option>
            </select>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>