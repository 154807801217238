export class OptionGroup {
  id!: string;
  name!: string;
  type: any;
  options!: Option[];
}

export class Option {
  id!: string;
  label!: string;
  value!: string;
  isDefault!: boolean;
  groupId!: string;
}
