import { Image } from "./product.model";
import { CompanyTemplate } from "./template.model";
import { Address } from "./user.model";

export class Company {
  id!: string;
  name!: string;
  logo: Image = new Image();
  address: Address = new Address();
  phone!: string;
  email!: string;
  currency: string;
  about: string;
  domainName!: string;
  paymentGateway: PaymentGateway = new PaymentGateway();
  salesTerms: SalesTerms = new SalesTerms();
  shippingTerms: ShippingTerms = new ShippingTerms();
  subscription: Subscription = new Subscription();
  template: CompanyTemplate[] = [];
  created: Date;
}


export class PaymentGateway {
  provider!: string;
  attributes: Attribute[] = [];
}

export class Attribute {
  name: string;
  value: string;
  secured: boolean;
}

export class Subscription {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  autoRenew: boolean;
  subscriptionPayment: SubscriptionPayment = new SubscriptionPayment();
  features: Features = new Features;
  status: string;
}

export class SubscriptionPayment {
  customerId: string;
  subscriptionId: string;
  paymentMethodId: string;
  type: string | undefined;
  last4: string | undefined;
  priceId: string;
  amount: number;
}



export class ShippingTerms {
  description!: string;
  shipOnWeekends: boolean = false;
  carriers: string[] = [];
}

export class SalesTerms {
  returnPolicy!: string;
  salesTax!: number;
  fees!: Map<string, number>;
}

export class Features {
  inventory: boolean = false;
  pos: boolean = false;
  trackCosts: boolean = false;
  shippingIntg: boolean = false;
  offerings!: string;
}
