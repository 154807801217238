import { Image } from "./product.model";

export class Promotion {
  id!: string;
  type!: string;
  startDate!: Date;
  endDate!: Date;
  code!: string;
  text!: string;
  image!: Image;
  terms!: PromoTerms;
  active!: boolean;
  canBePaired!: boolean;
  allProducts!: boolean;
  products!: Array<string>;
  selected: boolean = false;
}

export class PromoTerms {
  discountType: string;
  amount!: number;
  qualifyingAmount!: number;
  maxUses!: number;
  maxUsesPerCustomer!: number;
}
