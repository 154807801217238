import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NgbDate, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { applyTemplateToCompany } from 'src/app/actions/template.actions';
import { CompanyTemplate, Template } from 'src/app/model/template.model';
import { AppState, selectCompanyTemplates, selectCurrentCompany, selectTemplate, selectTemplates } from 'src/app/reducers';


@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit  {

  templates$: Observable<Template[]>;
  companyTemplates$: Observable<CompanyTemplate[]>;
  companyTemplate: CompanyTemplate = new CompanyTemplate();
  templateSelected: boolean = false;
  startDate: NgbDate | null;
  endDate: NgbDate | null;

  constructor(private store: Store<AppState>, private router: Router, private formatter: NgbDateParserFormatter, private dateAdapter: NgbDateAdapter<Date>) { }


  ngOnInit(): void {
    this.templates$ = this.store.select(selectTemplates);
    this.companyTemplates$ = this.store.select(selectCompanyTemplates);
    this.store.select(selectCurrentCompany).subscribe(c => this.companyTemplate.companyId = c.id);

  }

  pickCompanyTemplate(id : String) {
    this.router.navigate(['/application/template/',id]);
  }

  pickTemplate(id : String){
    this.companyTemplate.templateId = id.toString();
    this.templateSelected = true;

  }

  applyTemplate(){
    let start = this.formatter.format(this.startDate);
    if(start){
      this.companyTemplate.startDate = new Date(start);
    }
    let end = this.formatter.format(this.endDate);
    if(end){
      this.companyTemplate.endDate = new Date(end);
    }
    this.store.dispatch(applyTemplateToCompany({template: this.companyTemplate}))
  }




}
