// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const baseUrl = 'https://api-demo.mobiecom.com';

export const environment = {
  production: false,
  clientId: 'portal',
  authUrl: 'https://auth-dev.mobiecom.com/portal',
  discoveryUrl: 'https://auth-dev.mobiecom.com/portal/.well-known/oauth-authorization-server',
  productUrl: baseUrl + '/product/api/v1/admin',
  companyUrl: baseUrl + '/company/api/v1/company',
  categoryUrl: baseUrl + '/product/api/v1/admin/category',
  userUrl: baseUrl + '/profile/api/v1/admin',
  orderUrl: baseUrl + '/order/api/v1/admin',
  stripeKey: 'pk_test_51ISsVmD53KYLiCTV5k51ISLxgeQ5qCPD7kJbH24cFddounMwV70ELwiP7dJLBstkb1OZGrLsPFFAWmyc44xHkhiB00qfYyjFxS',
  hotjar: '2425950',
  templateUrl: baseUrl + '/template/api/v1',
  websocketUrl: 'wss://api-demo.mobiecom.com/company/ws/websocket',
  appRunnerUrl: 'https://app-runner.mobiecom.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
