import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { PromoTerms, Promotion } from 'src/app/model/promotion.model';
import { AppState, selectCurrentCompany, selectProducts, selectPromo, selectSearchResultProducts } from 'src/app/reducers';
import { createPromo, updatePromo } from 'src/app/actions/promotions.actions';
import { faCalendar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Product, Image } from 'src/app/model/product.model';
import { searchProducts } from 'src/app/actions/products.actions';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FileHandle } from '../file-dnd/dnd.directive';

@Component({
  selector: 'app-promotion-create-edit',
  templateUrl: './promotion-create-edit.component.html',
  styleUrls: ['./promotion-create-edit.component.scss'],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class PromotionCreateEditComponent implements OnInit {

  promotion = new Promotion();
  products = new Array<Product>()
  availableProducts$: Observable<Product[]>;

  showProductSearch: boolean = false;

  faInfoCircle = faInfoCircle;
  faCal = faCalendar;
  page = 1;
  pageSize = 10;
  selectedAll: any;
  today = new Date();

  hoveredDate: NgbDate | null = null;
  startDate: NgbDate | null;
  endDate: NgbDate | null;

  companyId: string;
  files =  new Array<File>();
  image = new Image();
  uploadedFile = new Array<FileHandle>();

  constructor(private router: Router, private route: ActivatedRoute, private store: Store<AppState>, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private dateAdapter: NgbDateAdapter<Date>) {}

  ngOnInit(): void {
    this.store.select(selectCurrentCompany).subscribe(company => this.companyId = company.id);
    this.promotion.terms = new PromoTerms();
    this.availableProducts$ = this.store.select(selectSearchResultProducts);

    const id = this.route.snapshot.paramMap.get('id');
    if(id) {
      this.store.select(selectPromo, id).subscribe(p => {
        if(p){
          this.promotion = JSON.parse(JSON.stringify(p));
          this.startDate = NgbDate.from(this.dateAdapter.fromModel(new Date(this.promotion.startDate)));
          this.endDate = NgbDate.from(this.dateAdapter.fromModel(new Date(this.promotion.endDate)));
          this.availableProducts$.subscribe(prods => {
            p.products.forEach(pr => {
              const product = prods.find(p => p.id === pr);
              if (product){
                this.products.push(product);
              }
            })
          })
        }
    })
    
  }
}

  getTitle() {
    return this.promotion.id?'Update':'Create'
  }

  onDateSelection(date: NgbDate) {
    if (!this.startDate && !this.endDate) {
      this.startDate = date;
    } else if (this.startDate && !this.endDate && date && date.after(this.startDate)) {
      this.endDate = date;
    } else {
      this.endDate = null;
      this.startDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.startDate && !this.endDate && this.hoveredDate && date.after(this.startDate) &&
        date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) { return this.endDate && date.after(this.startDate) && date.before(this.endDate); }

  isRange(date: NgbDate) {
    return date.equals(this.startDate) || (this.endDate && date.equals(this.endDate)) || this.isInside(date) ||
        this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  onChange(event?: any){
    console.log(event);
  }

  onValueChange(file: FileHandle[]) {
    file.forEach((f, i) => {
      if(!this.files.includes(f.file)) {
        const reader = new FileReader();

        reader.readAsDataURL(f.file);
        reader.onload = () => {
          this.files.push(f.file);
          var image = new Image();
          image.fileName = f.file.name;
          image.order = i;
          this.image = image
          this.promotion.image = image;
        };
      }
    });
  }

  selectProducts(){
    this.showProductSearch = !this.showProductSearch;
  }

  addProducts(event: Product[]){
    event.forEach(product => {
      if (!this.products.find(p => p.id === product.id)) {
        this.products.push(product)
      }
    });
    this.showProductSearch = false;
    
  }

  editProduct(id: String) {
    this.router.navigate(['/products/', id])
  }

  selectAll() {
    this.selectedAll = !this.selectedAll;

    this.products.forEach(prod => prod.selected = this.selectedAll)
  }

  checkIfAllSelected() {  
    var totalSelected =  0;
    this.products.forEach(prod => {
      if(prod.selected)totalSelected++;
    })
    this.selectedAll = totalSelected === this.products.length;

    return true;
  }

  getImageUrl(product: Product) {
    return product.variants[0]?.images[0]?.filePath
  }

  search() {
    this.store.dispatch(searchProducts({query: '', page: 0, size: this.pageSize, companyId: this.companyId}));
  }

  submit(promotion: Promotion) {
    let start = this.formatter.format(this.startDate);
    if(start){
      this.promotion.startDate = new Date(start);
    }
    let end = this.formatter.format(this.endDate);
    if(end){
      this.promotion.endDate = new Date(end);
    }
    this.promotion.products = this.products.map(p => p.id);
    if (!this.promotion.id) {
      this.store.dispatch(createPromo({promotion: promotion, companyId: this.companyId, file: this.files[0]}));
    } else {
      this.store.dispatch(updatePromo({promotion: promotion, companyId: this.companyId, file: this.files[0]}));
    }
  }

  editPromoImage(){
    this.image = this.promotion.image;
    this.promotion.image = new Image();
  }

  cancelEditLogo(){
    this.promotion.image = this.image;
  }

}
