import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as ProductsActions from '../actions/products.actions';
import { ProductService } from '../service/product.service';
import { mergeMap, map, catchError, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, selectProducts } from '../reducers';
import { Product } from '../model/product.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ProductsEffects {
    
    constructor(private actions$: Actions, private productService: ProductService, private store: Store<AppState>, private router: Router, private toastr: ToastrService){}


    searchProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.searchProducts),
            switchMap((payload: { query: String, page: number, size: number, companyId: string}) => {
                return this.productService.searchProducts(payload.query, payload.page, payload.size, 'name', 'asc', payload.companyId).pipe(
                    map(products => ProductsActions.searchProductsSuccess({ products })
                    ),
                    catchError(() => of(ProductsActions.searchProductsFailure())
                    )
                    );
                }
            )
        )
    )

    loadProduct$ = createEffect(() =>
    this.actions$.pipe(
        ofType(ProductsActions.loadProduct),
        switchMap((payload: { productId: String, companyId: string}) => {
            return this.productService.getProduct(payload.productId, payload.companyId).pipe(
                map(product => ProductsActions.loadProductSuccess({ product })
                ),
                catchError(() => of(ProductsActions.loadProductFailure())
                )
                );
            }
        )
    )
)

    createProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.createProduct),
            switchMap((payload: { product: Product, files: File[], companyId: string}) => {
                return this.productService.addProduct(payload.product, payload.companyId, payload.files).pipe(
                    map(product => ProductsActions.createProductSuccess({ product })
                    ),
                    catchError(() => of(ProductsActions.createProductFailure()))
                )
            })
        )
    )

    updateProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.updateProduct),
            switchMap((payload: { product: Product, files: File[], companyId: string}) => {
                return this.productService.updateProduct(payload.product, payload.companyId, payload.files).pipe(
                    map(product => ProductsActions.updateProductSuccess({ product })
                    ),
                    catchError(() => of(ProductsActions.updateProductFailure()))
                )
            })
        )
    )

    productCreateSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.createProductSuccess),
            tap(() => {
                this.router.navigate(['/products/list']);
                this.toastr.success('Product Created Sucessfully');
            })
        ), { dispatch: false }
    )

    productUpdateSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.updateProductSuccess),
            tap(() => {
                this.router.navigate(['/products/list']);
                this.toastr.success('Product Updated Sucessfully');
            })
        ), { dispatch: false }
    )

    productCreateFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.createProductFailure),
            tap(() => {
                this.toastr.error('Failed to Create Product');
            })
        ), { dispatch: false }
    )

    productUpdateFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProductsActions.updateProductFailure),
            tap(() => {
                this.toastr.error('Failed to Update Product');
            })
        ), { dispatch: false }
    )

}