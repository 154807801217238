<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
    <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">
              <h5 class="title">Edit Company</h5>
            </div>
            <div class="card-body" *ngIf="company$ | async">
              <form (submit)="submit(company)">
                <div class="row">
                  <div class="col-md-5 pr-1">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Company" [(ngModel)]="company.name" name="name">
                    </div>
                  </div>
                  <div class="col-md-4 px-1">
                    <div class="form-group">
                      <input type="email" class="form-control" placeholder="email" [(ngModel)]="company.email" name="email">
                    </div>
                  </div>
                  <div class="col-md-3 pl-1">
                    <div class="form-group">
                      <input name="phone" type="text" class="form-control" placeholder="Phone" [(ngModel)]="company.phone">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Address" [(ngModel)]="company.address.line1" name="line1">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 pr-1">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="City" [(ngModel)]="company.address.city" name="city">
                    </div>
                  </div>
                  <div class="col-md-4 px-1">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="State" [(ngModel)]="company.address.state" name="state">
                    </div>
                  </div>
                  <div class="col-md-4 pl-1">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Zip Code" [(ngModel)]="company.address.postalCode" name="zip">
                    </div>
                  </div>
                </div>
                <div class="row pr-2">
                  <div class="col-md-12 pr-1">
                    <div class="form-group">
                      <textarea class="company-about" rows="7" placeholder="Tell your customers about your company" [(ngModel)]="company.about" name="about"></textarea>
                    </div>
                  </div>
                </div>
                <div>Currency</div>
                <div class="row pr-2">
                  <div class="col">
                    <div ngbDropdown class="d-inline-block">
                      <button type="button" class="btn btn-outline-primary" id="currencyDD" ngbDropdownToggle>{{company.currency == null ? 'Select One' : currencyLabel(company.currency)}}</button>
                      <div ngbDropdownMenu aria-labelledby="currencyDD">
                        <button ngbDropdownItem (click)="setCurrency('USD', $event)">Dollar</button>
                        <button ngbDropdownItem (click)="setCurrency('EUR', $event)">Euro</button>
                        <button ngbDropdownItem (click)="setCurrency('GBP', $event)">Pound</button>
                      </div>
                    </div>
                  </div>
                </div>
                <h4>Payment Gateway Information</h4>
                <div class="row">
                  <div class="col-md-4 pr-1">
                    <div ngbDropdown class="d-inline-block">
                      <button type="button" class="btn btn-outline-primary" id="gatewayDD" ngbDropdownToggle>{{company.paymentGateway.provider == null ? 'Provider' : company.paymentGateway.provider}}</button>
                      <div ngbDropdownMenu aria-labelledby="gatewayDD">
                        <button ngbDropdownItem (click)="setGateway('STRIPE', $event)">STRIPE</button>
                        <button ngbDropdownItem (click)="setGateway('SQUARE', $event)">SQUARE</button>
                        <!-- <button ngbDropdownItem (click)="setGateway('AUTHORIZE.NET', $event)">AUTHORIZE.NET</button> -->
                      </div>
                    </div>
                  </div>
                  <div [ngSwitch]="company.paymentGateway.provider" class="col-md-4 px-1">
                    <div *ngSwitchCase="'STRIPE'" >
                      <div class="form-group">
                        <input type="password" class="form-control" placeholder="API Key" [ngModel]="readAttribute('APIKey')" (ngModelChange)="setAttribute('APIKey', $event, true)" name="API-key">
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Publishable Key" [ngModel]="readAttribute('publishableKey')" (ngModelChange)="setAttribute('publishableKey', $event, false)" name="publishable-key">
                      </div>
                    </div>
                    <div *ngSwitchCase="'SQUARE'">
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="App ID" [ngModel]="readAttribute('appId')" (ngModelChange)="setAttribute('appId', $event, false)" name="app-id">
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Location ID" [ngModel]="readAttribute('locationId')" (ngModelChange)="setAttribute('locationId', $event, false)" name="location-id">
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Access Token" [ngModel]="readAttribute('accessToken')" (ngModelChange)="setAttribute('accessToken', $event, false)" name="access-token">
                      </div>
                    </div>
                    <div *ngSwitchDefault>
                      Please Select a Gateway Provider
                    </div>
                  </div>
                </div>
                <h4>Subscription</h4>
                <div class="row">
                  <div class="col-md-4 pr-1">
                    <span class="form-check-sign">Plan {{ company.subscription.name == "TRIAL"? "(" + company.subscription.name + ")": null  | uppercase}}</span>
                    <div class="col-md-4 form-group">
                      <div class="form-check-radio">
                        <input id="basic" type="radio" class="form-check-input" value="Basic" [(ngModel)]="company.subscription.name" name="plan" (click)="changePlan($event)">
                        <label for="basic">Basic</label>
                      </div>
                      <div class="form-check-radio">
                        <input id="business" type="radio" class="form-check-input" value="Business" [(ngModel)]="company.subscription.name" name="plan" (click)="changePlan($event)">
                        <label for="business">Business</label>
                      </div>
                      <div class="form-check-radio">
                        <input id="busPlus" type="radio" class="form-check-input" value="Business Plus" [(ngModel)]="company.subscription.name" name="plan" (click)="changePlan($event)">
                        <label for="busPlus">Business Plus</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 px-1">
                    <div class="form-group">
                      <label>Member Since</label>
                      {{ company.created | date }}
                    </div>
                    <div *ngIf="company.subscription.name == 'TRIAL'" class="form-group">
                      <label>Trial Ends</label>
                      {{ company.subscription.endDate | date }}
                    </div>
                    <div *ngIf="company.subscription.name != 'TRIAL' && !updatePayment">
                      <div class="form-group" *ngIf="company.subscription.subscriptionPayment.last4">
                        <label>Payment Info</label>
                        {{ company.subscription.subscriptionPayment.type }} - {{ company.subscription.subscriptionPayment.last4 }}
                      </div>
                      <button class="btn btn-round btn-default" type="button" (click)="updatePay()">Edit Payment</button>
                    </div>
                    <div *ngIf="company.subscription.name != 'TRIAL' && updatePayment" class="form-group">
                      <label>Enter Payment</label>
                      <ngx-stripe-card
                        [options]="cardOptions"
                        [elementsOptions]="elementsOptions"
                      ></ngx-stripe-card>
                      
                      <button class="btn btn-round btn-default" type="button" (click)="saveCard()">Save Card</button>
                    </div>
                  </div>
                  <div class="col-md-4 pl-1" *ngIf="company.subscription.name != 'TRIAL'">
                    <div class="form-group">
                      <label>Amount</label>
                      {{ company.subscription.subscriptionPayment.amount | currency }} / mo.
                    </div>
                  </div>
                </div>
                <div class="col-md-2 pr-1">
                  <button type="submit" class="btn btn-round btn-default btn-simple">Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-user">
            <div class="image">
            </div>
            <div class="card-body">
              <div class="author">
                <div *ngIf="company.logo?.filePath; else fileUpload" (click)="editLogo()">
                  <img class="avatar border-gray" src="{{company.logo.filePath}}" alt="...">
                  <h5 class="title">{{company.name}}</h5>
                </div>
                <ng-template #fileUpload>
                  <h5 class="title">Upload Company Logo</h5>
                  <app-file-dnd (uploadedFiles)="onValueChange($event)"></app-file-dnd>
                  <div *ngIf="logo">
                      <button type="button" class="btn btn-round btn-default" (click)="cancelEditLogo()">Cancel</button>
                  </div>
                </ng-template>
              </div>
              <p class="description text-center">
                {{company.domainName}}
              </p>
            </div>
            <hr>
            <div class="button-container">
              <button href="#" class="btn btn-neutral btn-icon btn-round btn-lg">
                <i class="fab fa-facebook-f"></i>
              </button>
              <button href="#" class="btn btn-neutral btn-icon btn-round btn-lg">
                <i class="fab fa-twitter"></i>
              </button>
              <button href="#" class="btn btn-neutral btn-icon btn-round btn-lg">
                <i class="fab fa-google-plus-g"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
</div>