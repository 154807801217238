export class Inventory {
  id!: string;
  quantity!: number;
  location: Location;
}

export class Location {
  site: string;
  attributes: Map<String,String>;
}
