import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchResult } from '../model/product.model';
import { Promotion } from '../model/promotion.model';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(private httpClient: HttpClient) { }

  search(query: String,
    page: number = 0,
    size: number,
    sortColumn: String,
    sort: String,
    companyId: String
  ): Observable<SearchResult> {
    let params;
    let sortQuery = sortColumn + ',' + sort;
    if (query != undefined) {
      params = new HttpParams()
        .set('q', String(query))
        .set('page', String(page))
        .set('size', String(size))
        .set('sort', String(sortQuery))
        .set('companyId', String(companyId));
    } else {
      params = new HttpParams()
        .set('page', String(page))
        .set('size', String(size))
        .set('sort', String(sortQuery))
        .set('companyId', String(companyId));
    }
    return this.httpClient.get<SearchResult>(
      environment.productUrl + '/promotion/search',
      { params }
    );
  }

  create(promotion: Promotion, companyId: String, file?: File ) {
    let params = new HttpParams().set('companyId', String(companyId));
    let pipe = new DatePipe('en-US');
    const formData = new FormData();
    formData.append('promotion', new Blob([JSON.stringify(promotion, function(k,v){ 
      if (this[k] instanceof Date) {
        return pipe.transform(this[k], 'yyyy-MM-dd');
      }
      return v;
    })], { type: 'application/json'}));
    if (file) {
      formData.append('file', file);
    }
    return this.httpClient.post<Promotion>(environment.productUrl + '/promotion', formData, { params });
  }


  update(promotion: Promotion, companyId: String, file?: File) {
    let params = new HttpParams().set('companyId', String(companyId));
    let pipe = new DatePipe('en-US');
    const formData = new FormData();
    formData.append('promotion', new Blob([JSON.stringify(promotion, function(k,v){ 
      if (this[k] instanceof Date) {
        return pipe.transform(this[k], 'yyyy-MM-dd');
      }
      return v;
    })], { type: 'application/json'}));
    if (file) {
      formData.append('file', file);
    }
    return this.httpClient.put<Promotion>(environment.productUrl + '/promotion/' + promotion.id, formData, { params });
  }
}
