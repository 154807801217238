import { Product } from "../model/product.model";
import { createReducer, on, Action } from "@ngrx/store";
import * as ProductsActions from '../actions/products.actions';


export interface ProductsState {
    products: Product[];
    activePage: number;
    totalPages: number;
}

export const initialProductsState = {
    products: new Array<Product>(),
    activePage: 0,
    totalPages: 0
}

const reducer = createReducer(
    initialProductsState,
    on(ProductsActions.searchProductsSuccess,
        (state, { products }) => ({
            ...state, products: products.content, activePage: products.number, totalPages: products.totalPages
        })
    ),
    on(ProductsActions.createProductSuccess,
        (state, { product }) => ({
            ...state, products: mergeProducts(state.products, [product])
        })
    ),
    on(ProductsActions.updateProductSuccess,
        (state, { product }) => ({
            ...state, products: updateProduct(state.products, product)
        })
    ),
    on(ProductsActions.loadProductSuccess,
        (state, { product }) => ({
            ...state, products: mergeProducts(state.products, [product])
        })
    ),
)


function mergeProducts(state: Product[], products: Product[]){
    let newProducts = [...state, ...products]
    const uniqueArray = newProducts.reduce((newArray: Product[], item) =>{
        if (newArray.find(i => i.id === item.id)){
            return newArray
        } else {
            return [...newArray, item]
        }
    }, [])
    return uniqueArray;
}

function updateProduct(state: Product[], product: Product) {
    let newArray = [...state];
    newArray.forEach((s, i) => {
        if(s.id === product.id) {
            newArray[i] = product;
        }
    });
    return newArray;
}

export function productReducer(state: ProductsState | undefined, action: Action) {
    return reducer(state, action);
}

