import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserService } from "../service/user.service";
import * as UserActions from "../actions/user.actions";
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { of } from "rxjs";
import { CompanyUser } from "../model/user.model";
import { Router } from "@angular/router";

@Injectable()
export class UserEffects {
    
    constructor(private actions$: Actions, private userService: UserService, private router: Router){ }

    loadCompanyUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.loadCompanyUsers),
            switchMap((payload: {companyId: String}) => 
                this.userService.getCompanyUsers(payload.companyId).pipe(
                map(users =>
                    UserActions.loadCompanyUsersSuccess({ users })
                ),
                catchError((error) => {
                    return of(UserActions.loadCompanyUsersFailure())
                }
                )
            )
        )
        )
    )

    loadMyUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.loadMyUser),
            switchMap((payload: {companyId: String}) => 
                this.userService.getMyCompanyUser(payload.companyId).pipe(
                map(user =>
                    UserActions.loadMyUserSuccess({ user })
                ),
                catchError((error) => {
                    return of(UserActions.loadMyUserFailure())
                }
                )
            )
        )
        )
    )

    updateCompanyUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateCompanyUser),
            switchMap((payload: { user: CompanyUser, companyId: String}) => {
                return this.userService.updateCompanyUser(payload.user, payload.companyId).pipe(
                    map(user => UserActions.updateCompanyUserSuccess({ user })
                    ),
                    catchError(() => of(UserActions.updateCompanyUserFailure()))
                )
            })
        )
    )

    userSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateCompanyUserSuccess),
            tap(() => {
                this.router.navigate(['/profile']);
            })
        ), { dispatch: false }
    )
}