import { Action, createReducer, on } from "@ngrx/store";
import { Category, CategoryNode } from "../model/category.model";
import * as CategoryActions from "../actions/category.actions";

export interface CategoryState {
    categoryTree: CategoryNode[];
    categories: Category[];
}

export const initialCategoryState = {
    categoryTree: new Array<CategoryNode>(),
    categories: new Array<Category>()
}

const reducer = createReducer(
    initialCategoryState,
    on(CategoryActions.loadCategoryTreeSuccess,
        (state, { categories }) => ({
            ...state, categoryTree: categories
        })
    ),
    on(CategoryActions.loadCategoriesSuccess,
        (state, { categories }) => ({
            ...state, categories: categories
        })
    )
)

export function categoryReducer(state: CategoryState | undefined, action: Action) {
    return reducer(state, action);
}