import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as CompanyActions from '../actions/company.actions';
import { switchMap, map, catchError, mergeMap, tap } from "rxjs/operators";
import { CompanyService } from "../service/company.service";
import { from, Observable, of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Company } from "../model/company.model";

@Injectable()
export class CompanyEffects {
    
    constructor(private actions$: Actions, private companyService: CompanyService, private toastr: ToastrService){ }

    loadCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyActions.loadCompany),
            switchMap((payload: {id: String}) => {
                return this.companyService.getCompany(payload.id).pipe(
                    map(company =>
                        CompanyActions.loadCompanySuccess({ company })
                    ),
                    catchError(() =>
                        of(CompanyActions.loadCompanyFailure())
                    ));
                }
            )
        )
    )

    loadCompanies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyActions.loadCompanies),
            mergeMap(() => 
                this.companyService.getCompanies().pipe(
                    map(companies =>
                        CompanyActions.loadCompaniesSuccess({ companies })
                    ),
                    catchError((error) => {
                        console.log(error)
                        return of(CompanyActions.loadCompaniesFailure())
                    }
                    )
                )
            )
        )
    )

    setCurrentCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyActions.setCurrentCompany),
            switchMap(({company: company}) => {
                return of(company).pipe(
                    map(id =>
                        CompanyActions.setCurrentCompanySuccess({ company })
                    ),
                    catchError(() =>
                        of(CompanyActions.setCurrentCompanyFailure())
                    ));
                }
            )
        )
    )

    updateCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyActions.updateCompany),
            switchMap(({company: company}) => 
                this.companyService.updateCompany(company).pipe(
                    map(company =>
                        CompanyActions.updateCompanySuccess({ company })
                    ),
                    catchError(() =>
                        of(CompanyActions.updateCompanyFailure())
                    )
                )
            )
        )
    )

    updateCompanySuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyActions.updateCompanySuccess, CompanyActions.updateCompanyLogoSuccess),
            tap((payload: {company: Company}) => {
                this.toastr.success('Company Saved');
            })
        ), { dispatch: false }
    )

    updateCompanyFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyActions.updateCompanyFailure, CompanyActions.updateCompanyLogoFailure),
            tap(() => {
                this.toastr.error('Company Update Failed');
            })
        ), { dispatch: false }
    )

    updateCompanyLogo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CompanyActions.updateCompanyLogo),
            switchMap(({company: company, image: image}) => 
                this.companyService.updateCompanyLogo(company.id, image).pipe(
                    map(image =>
                        CompanyActions.updateCompanyLogoSuccess({ company, image })
                    ),
                    catchError(() =>
                        of(CompanyActions.updateCompanyLogoFailure())
                    )
                )
            )
        )
    )

}