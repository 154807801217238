<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Add Tracking</h4>
    <button type="button" class="btn-close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn btn-outline-primary" id="shipper" ngbDropdownToggle></button>
        <div ngbDropdownMenu aria-labelledby="shipper">
          <button ngbDropdownItem (click)="setShipper('UPS')">UPS</button>
          <button ngbDropdownItem (click)="setShipper('USPS')">USPS</button>
          <button ngbDropdownItem (click)="setShipper('FedEx')">FedEx</button>
        </div>
    </div>
    <label for="tracking-number">Tracking Nubmer:</label>
    <input name="tracking-number" type="text" [(ngModel)]="tracking.number">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="updateTracking()">Ok</button>
  </div>
