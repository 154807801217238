import { Action, createReducer, on } from "@ngrx/store"
import { Promotion } from "../model/promotion.model"
import * as PromotionsActions from '../actions/promotions.actions';

export interface PromotionsState {
    promotions: Promotion[];
    activePage: number;
    totalPages: number;
}

export const initialPromotionsState = {
    promotions: new Array<Promotion>(),
    activePage: 0,
    totalPages: 0
}

const reducer = createReducer(
    initialPromotionsState,
    on(PromotionsActions.loadPromosSuccess,
        (state, { promotions }) => ({
            ...state, promotions: promotions.content, activePage: promotions.number, totalPages: promotions.totalPages
        })
    ),
    on(PromotionsActions.createPromoSuccess,
        (state, { promotion }) => ({
            ...state, promotions: mergePromos(state.promotions, [promotion])
        })
    ),
    on(PromotionsActions.updatePromoSuccess,
        (state, { promotion }) => ({
            ...state, promotions: mergePromos(state.promotions, [promotion])
        })
    )
)

function mergePromos(state: Promotion[], promotions: Promotion[]){
    let newPromos = [...state, ...promotions]
    const uniqueArray = newPromos.reduce((newArray: Promotion[], item) =>{
        if (newArray.find(i => i.id === item.id)){
            return newArray
        } else {
            return [...newArray, item]
        }
    }, [])
    return uniqueArray;
}

export function promotionReducer(state: PromotionsState | undefined, action: Action) {
    return reducer(state, action);
}