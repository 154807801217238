<div class="panel-header panel-header-sm">
</div>
<div class="main-content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
            <div class="card-header">
                <h4 class="card-title">Templates</h4>
            </div>
            <div class="card-body" *ngIf="template$ | async">
                <div>
                    <h4 class="card-title"> {{template.name}} </h4>
                </div>
                <div class="row">
                    <div class="toolbox col-md-4">
                        <div ngbAccordion [closeOthers]="true" [destroyOnHide]="false">
                                <div ngbAccordionItem *ngFor="let menu of toolboxJson">
                                    <h2 ngbAccordionHeader>
                                        <button ngbAccordionButton class="btn btn-icon toolbox-button" (click)="changeRoute(menu.page)">
                                            <fa-icon class="toolbox-icon" [icon]="getFaIcon(menu.icon)"></fa-icon>
                                            <div class="display-6"> &nbsp;{{ menu.name }}</div>
                                        </button>
                                    </h2>
                                    <div ngbAccordionCollapse>
                                        <div ngbAccordionBody>
                                            <ng-template>
                                                <div class="col justify-content-center">
                                                    <div *ngFor="let menuItem of $any(menu.options)" class="row">
                                                        <div class="col-md-6">
                                                            {{ menuItem.name }}:
                                                            <fa-icon *ngIf="menuItem.info != null" [icon]="faInfoCircle" [ngbTooltip]="menuItem.info"></fa-icon>
                                                        </div>                                        
                                                        <div class="col-md-4" *ngIf="menuItem.type == 'color-selector'">
                                                            <ngx-colors ngx-colors-trigger (input)="updateAttr(menuItem.attributes, $event)" [(ngModel)]="menuItem.value"></ngx-colors>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="menuItem.type == 'icon-selector'">
                                                            <div ngbDropdown class="dropdown col-md-4" placement="bottom-left" *ngIf="hasOptions(menuItem.options); else picker">
                                                                <button class="btn btn-default btn-sm dropdown-toggle" ngbDropdownToggle><i class="material-icons mat-icon-picker">{{menuItem.value}}</i></button>
                                                                <div ngbDropdownMenu>
                                                                    <button class="dropdown-item" *ngFor="let opt of menuItem.options | keyvalue : returnZero" (click)="changeOpt(menuItem.attributes, $any(opt.value))"><i class="material-icons">{{opt.key}}</i></button>
                                                                </div>
                                                            </div>
                                                            <ng-template #picker>
                                                                <button class="btn btn-default btn-sm" [iconPicker]="menuItem.value" [ipFallbackIcon]="''" [ipIconPack]="['mat']" (iconPickerSelect)="updateAttr(menuItem.attributes, $event)">
                                                                    <span class="material-icons mat-icon-picker">{{ menuItem.value }}</span>   
                                                                </button>
                                                            </ng-template>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="menuItem.type == 'file-selector'">
                                                            <input type="file" class="file-input" (change)="onFileSelectedAtt($event, menuItem.attributes)" #fileUpload>
                                                            <div class="file-upload" (click)="fileUpload.click()">    
                                                                <img class="sample-img" [src]="menuItem.value">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="menuItem.type == 'input'" class="input-group">
                                                            <input class="form-control" placeholder="menuItem.name" type="text" [(ngModel)]="menuItem.value" (keyup.enter)="updateAttr(menuItem.attributes, menuItem.value)">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text">
                                                                    <fa-icon [icon]="faArrowAltCircleRight" (click)="updateAttr(menuItem.attributes, menuItem.value)"></fa-icon>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="menuItem.type == 'checkbox'">
                                                            <input class="form-check-input" type="checkbox" value="" [(ngModel)]="menuItem.value" (change)="updateAttr(menuItem.attributes, menuItem.value)">
                                                        </div>
                                                        <div ngbDropdown class="dropdown col-md-4" placement="bottom-left" *ngIf="menuItem.type == 'dropdown'">
                                                            <button class="btn btn-default btn-sm dropdown-toggle" ngbDropdownToggle>{{menuItem.name}}</button>
                                                            <div ngbDropdownMenu>
                                                                <button class="dropdown-item" *ngFor="let opt of menuItem.options | keyvalue : returnZero" (click)="changeOpt(menuItem.attributes, $any(opt.value))">{{opt.key}}</button>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4" *ngIf="menuItem.type == 'drag-and-drop-list'">
                                                            <div cdkDropList class="component-list" (cdkDropListDropped)="drop($event, menuItem.options, menuItem.value, menuItem.attributes)">
                                                                <div class="component-box" *ngFor="let val of menuItem.value" cdkDrag>{{val}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
				                        </div>
			                        </div>
		                        </div>
                            </div>
                        </div>
                    <div class="col-md-8">
                        <div class="row justify-content-center sticky-top" [class.blur]="updating">
                            <div class="marvel-device iphone-x">
                                <div class="top-bar"></div>
                                <div class="sleep"></div>
                                <div class="bottom-bar"></div>
                                <div class="volume"></div>
                                <div class="overflow">
                                    <div class="shadow shadow--tr"></div>
                                    <div class="shadow shadow--tl"></div>
                                    <div class="shadow shadow--br"></div>
                                    <div class="shadow shadow--bl"></div>
                                </div>
                                    <div class="inner-shadow"></div>
                                    <div class="screen">
                                        <div [hidden]="!loading" class="screen"><img src="assets/images/iphone_home.png"></div>
                                        <iframe *ngIf="userProfile$ | async" [hidden]="loading" class="screen" #iframe sandbox="allow-scripts allow-same-origin allow-popups" [src]="iframeUrl" (load)="updateSplashDefaults()"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>