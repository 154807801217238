import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { addTrackingToOrder, loadOrders } from 'src/app/actions/order.actions';
import { Company } from 'src/app/model/company.model';
import { Order, OrderProduct } from 'src/app/model/order.model';
import { AppState, selectCurrentCompany, selectOrder } from 'src/app/reducers';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private store: Store<AppState>) { }

  companyId: string;
  order$: Observable<Order | undefined>;
  order: Order;

  ngOnInit(): void {
    this.store.select(selectCurrentCompany).subscribe(company => {
      this.companyId = company.id;
    });
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.order$ = this.store.select(selectOrder, id)
      this.order$.subscribe(o => this.order = JSON.parse(JSON.stringify(o)))
    }
  }

  getImageUrl(product: OrderProduct) {
    return product.image;
  }

  submit(){
    this.store.dispatch(addTrackingToOrder({companyId: this.companyId, orderId: this.order.id, tracking: this.order.tracking}));
  }

  viewCustomer(id: string){
    this.router.navigate(['/customers/', id])
  }
}
