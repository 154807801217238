import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { searchProducts } from 'src/app/actions/products.actions';
import { Product, ProductVariant, SearchResult } from 'src/app/model/product.model';
import { AppState, selectCompany, selectCurrentCompany, selectSearchResultProducts } from 'src/app/reducers';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  products$: Observable<Product[]>;
  products = new Array<Product>();
  page = 1;
  pageSize = 10;
  totalProducts: 0;
  selectedAll: any;
  companyId: string;

  faPlus = faPlus;

  constructor(private route: Router, private store: Store<AppState>) { }

  ngOnInit(): void {
    this.store.select(selectCurrentCompany).subscribe(company => this.companyId = company.id);
    this.store.dispatch(searchProducts({query: '', page: 0, size: this.pageSize, companyId: this.companyId}));
    this.products$ = this.store.select(selectSearchResultProducts,0)
    this.products$.subscribe(prods => this.products = JSON.parse(JSON.stringify(prods)));
    
  }

  applyFilter(filterValue: any) {
    filterValue = filterValue.target.value.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
    this.store.dispatch(searchProducts({query: filterValue, page: 0, size: this.pageSize, companyId: this.companyId}));

  }
  selectAll() {
    this.selectedAll = !this.selectedAll;

    this.products.forEach(prod => prod.selected = this.selectedAll)
  }
  checkIfAllSelected() {  
    var totalSelected =  0;
    this.products.forEach(prod => {
      if(prod.selected)totalSelected++;
    })
    this.selectedAll = totalSelected === this.products.length;

    return true;
  }

  getQuantity(product: Product) {
    let qty = 0;
    product.variants.forEach(v => v.inventory.forEach(i => qty += i.quantity));
    return qty;
  }

  getImageUrl(product: Product) {
    return product.profileImage.filePath;
  }

  search() {
    //offset page by 1 since search endpoint starts at 0 and pagination component starts at 1
    this.store.dispatch(searchProducts({query: '', page: this.page -1, size: this.pageSize, companyId: this.companyId}));
  }

  addProduct(){
    this.route.navigate(['/products/new'])
  }

  editProduct(id: String) {
    this.route.navigate(['/products/', id])
  }

}
