import { Component, OnInit } from '@angular/core';
import { faCog, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ChartConfiguration, ChartData, ChartOptions } from 'chart.js';
import { Observable } from 'rxjs';
import { addTrackingToOrder, loadMonthSales, loadOrders, loadTopProducts, loadYTDSales } from 'src/app/actions/order.actions';
import { Order, OrderProduct, Sales } from 'src/app/model/order.model';
import { Review } from 'src/app/model/review.model';
import { AppState, selectCurrentCompany, selectMonthSales, selectOrders, selectTopProducts, selectYTDSales } from 'src/app/reducers';
import { AddEditTrackingComponent } from './add-edit-tracking/add-edit-tracking.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public salesChartType: any;
  public salesData: ChartConfiguration<'line'>['data'];
  public salesChartOptions: ChartOptions;
  public salesChartLabels: Array<any>;
  public salesChartColors: Array<any>
  public promoChartType: any;
  public promoData: ChartConfiguration<'line'>['data'];
  public promoChartOptions: ChartOptions;
  public promoChartLabels: Array<any>;
  public promoChartColors: Array<any>
  public topProductData: ChartConfiguration<'bar'>['data'];
  public topProductOptions: ChartOptions<'bar'>;
  public topProductLabels: Array<any>;
  public topProductColors: Array<any>
  public topProductType: any;
  public gradientFill: any;
  public canvas: any;
  public ctx: any;
  public gradientChartOptionsConfiguration: ChartOptions<'line'>;
  reviews$: Observable<Review[]>;
  orders$: Observable<Order[]>;
  readyToShip: Order[];
  ytdSales = new Array<number>();
  ytdLabels = new Array<string>();
  mtdSales = new Array<number>();
  mtdLabels = new Array<string>();
  topAmount = new Array<number>();
  topLabels = new Array<string>();
  companyId: string;
  
  faCog = faCog;
  faSyncAlt = faSyncAlt;

  constructor(private store: Store<AppState>, private _modalService: NgbModal) { }

  ngOnInit(): void {
    const formatter = new Intl.DateTimeFormat('default', { month: 'long' });
    const curMonth = formatter.format(new Date());
    this.store.select(selectCurrentCompany).subscribe(company => {
      this.companyId = company.id;
      this.store.dispatch(loadYTDSales({companyId: company.id}));
      this.store.dispatch(loadMonthSales({companyId: company.id, month: curMonth}));
      this.store.dispatch(loadOrders({companyId: company.id, page: 0, size: 10, query: "status:PAID"}));
      this.store.dispatch(loadTopProducts({companyId: company.id, daysBack: 30}));
    });
    this.store.select(selectYTDSales).subscribe(sales => {
      this.ytdSales = [];
      this.ytdLabels = [];
      sales.forEach(s => {
        this.ytdSales.push(s.amount);
        this.ytdLabels.push(s.label);
      });   
    });
    this.store.select(selectMonthSales).subscribe(sales => {
      this.mtdSales = [];
      this.mtdLabels = [];
      sales.forEach(s => {
        this.mtdSales.push(s.amount);
        this.mtdLabels.push(s.label);
      });   
    });
    this.orders$ = this.store.select(selectOrders);
    this.orders$.subscribe(ord => {
      this.readyToShip = ord.filter(o => o.status === 'PAID');
    })
    this.store.select(selectTopProducts).subscribe(top => {
      this.topAmount = [];
      this.topLabels = [];
      top.forEach(p => {
        this.topAmount.push(p.amount);
        this.topLabels.push(p.label);
      });
    })
      
    this.canvas = document.getElementById("salesChart");
    this.ctx = this.canvas.getContext("2d");
    this.gradientFill = this.ctx.createLinearGradient(0, 200, 0, 50);
    this.gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    this.gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.24)");
    this.gradientChartOptionsConfiguration = {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        y: {
          ticks: {
            stepSize: 250,
            callback: function(value: any) {
              return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
            }
          }
        },
        x: {
          min:0,
          max: this.ytdSales.reduce((a,b)=> Math.max(a,b))
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 10,
          top: 15,
          bottom: 15
        }
      }
    };

    this.salesChartColors = [
      {
        borderColor: "#f96332",
        pointBorderColor: "#FFF",
        pointBackgroundColor: "#f96332",
        backgroundColor: this.gradientFill
      }
    ];
    this.salesChartLabels = this.ytdLabels;
    this.salesChartOptions = this.gradientChartOptionsConfiguration;
  
    this.salesChartType = 'line';

    this.salesData = {
        labels: this.ytdLabels,
        datasets: [{
          data: this.ytdSales,
          label:'Sales',
          fill: true,
          pointBorderWidth: 2,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          borderWidth: 2,
          borderColor: "#f96332",
          pointBorderColor: "#FFF",
          pointBackgroundColor: "#f96332",
          backgroundColor: this.gradientFill
        }],
      };

    this.topProductType = 'bar';
    this.topProductData = {
        labels: this.topProductLabels,
        datasets: [{
          data: this.topAmount,
          label: "Quantity",
          borderWidth: 1,
          backgroundColor: this.gradientFill,
          borderColor: "#2CA8FF"
        }]
    };

  this.topProductLabels = this.topLabels
  this.topProductOptions = {
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 15,
          bottom: 15
        }
      }
    }

    this.promoChartColors = [
      {
        borderColor: "#f96332",
        pointBorderColor: "#FFF",
        pointBackgroundColor: "#f96332",
        backgroundColor: this.gradientFill
      }
    ];
    this.promoChartLabels = this.ytdLabels;
    this.promoChartOptions = this.gradientChartOptionsConfiguration;
  
    this.promoChartType = 'line';

    this.promoData = 
      {
        labels: this.promoChartLabels,
        datasets: [{
          label: "Sales",
          pointBorderWidth: 2,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 4,
          fill: true,
          borderWidth: 2,
          data: this.ytdSales
        }]
      };
   
  }

  chartClicked(e:any):void {
    console.log(e);
  }

  chartHovered(e:any):void {
  
  }

  updatePendingOrders(){

  }

  getOrderQty(products: OrderProduct[]){
    var total = 0;
    products.forEach(p => total += p.quantity);
    return total;
  }

  addTracking(order: Order){
    const modalRef = this._modalService.open(AddEditTrackingComponent);
    modalRef.componentInstance.user = order.tracking;
    modalRef.result.then((tracking: any) => {
      this.store.dispatch(addTrackingToOrder({companyId: this.companyId, orderId: order.id, tracking: tracking}))
    })
  }

  ytdSelect() {
    this.salesData = {
      labels: this.ytdLabels,
      datasets: [{
        data: this.ytdSales,
        label:'Sales',
        fill: true,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        borderWidth: 2,
        borderColor: "#f96332",
        pointBorderColor: "#FFF",
        pointBackgroundColor: "#f96332",
        backgroundColor: this.gradientFill
      }],
    };
  }

  mtdSelect() {
    this.salesData = {
      labels: this.mtdLabels,
      datasets: [{
        data: this.mtdSales,
        label:'Sales',
        fill: true,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        borderWidth: 2,
        borderColor: "#f96332",
        pointBorderColor: "#FFF",
        pointBackgroundColor: "#f96332",
        backgroundColor: this.gradientFill
      }],
    };
  }



}
