 <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"> Products</h4>
        </div>
        <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group no-border">
                  <input class="form-control" type="text" placeholder="Search Products" (keyup)="search($event)">
                </div>
              </div>
            </div>  
            <div *ngIf="products$ | async" class="table-responsive">              
                <table class="table">
                    <thead class="text-primary">
                        <tr>
                          <th scope="col">Select</th>
                          <th scope="col">Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let product of products">
                            <td><input class="form-check" type="checkbox" value="{{product.name}}" [(ngModel)]="product.selected"></td>
                            <td>
                              <img [src]="getImageUrl(product)" class="mr-2" style="width: 20px">
                              <ngb-highlight [result]="product.name"></ngb-highlight>
                            </td>
                          </tr>
                </table>
            </div>
            <div class="row">
                <div class="col-md-5 pr-1 float-right" >
                  <button type="button" class="btn btn-round btn-primary" (click)="addProducts()">
                    Add Products
                  </button>
                </div>
            </div>         
        </div>   
      </div>
    </div>
</div>
