import { Image } from "./product.model";

export class Template {
    id: string;
    name: string;
    type: string;
    images: Array<Image>;
    theme: Map<String, Object>;
    componentLayouts: Map<String, Object>; 
    toolbox: Array<Object>; 
    properties!: Map<String, Object>;
    createdAt: Date;

  }

export class CompanyTemplate {
  id: string;
  templateId: string;
  companyId: string;
  name: string;
  status: string;
  startDate: Date;
  endDate: Date;
  theme: Map<String, Object>;
  componentLayouts: Map<String, Object>;
}