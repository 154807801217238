import { Component, OnInit } from '@angular/core';
import { CompanyUser } from 'src/app/model/user.model';

@Component({
  selector: 'app-user-profile-create',
  templateUrl: './user-profile-create.component.html',
  styleUrls: ['./user-profile-create.component.scss']
})
export class UserProfileCreateComponent implements OnInit {

  companyUser = new CompanyUser();

  constructor() { }

  ngOnInit(): void {
    
  }

  submit() {

  }

}
